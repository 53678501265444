import moment from 'moment';
import 'moment/locale/tr';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Announcement from '../../assets_aplan/images/announcement.svg';
import Spinner from '../../components/templates/spinner';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import {
  CalendarModalSearchInitialValues,
  ActivityHoursInitialValues,
  ActivityHoursDataModelValues
} from '../../store/constants/exam-period-const';
import {
  Days
} from '../../store/constants/course-period-const';
import * as Types from '../../store/types';
import CourseAddModal from './course-hours-add-modal';
import CourseHoursListPage from './course-hours-listpage';
import ClassroomAddModal from './classroom-hours-add-modal';
import ClassroomListPage from './classroom-hours-listpage';
import Overlaps from './course-hours-overlaps';
import Translator from '../../services/translate-factory';
import * as GT from '../../tools/general-tools';
import CourseHourDetailTable from './course-hour-detail-table';
import { instructorRoles, isUserEquals, managerRoles } from '../../util/authorize';
import { UserRole } from '../../store/constants/enums';
import cn from '../../components/ui/Tailwind';

const T = Translator.create();
const Logger = Log.create('ExamDates');

function getInitialState(): Types.IExamPeriod_Activity_TabState {
  const initialValues: Types.IExamPeriod_Activity_TabState = {
    model: Object.assign({}, ActivityHoursInitialValues),
    dataModel: Object.assign({}, ActivityHoursDataModelValues),
    filters: Object.assign({}, CalendarModalSearchInitialValues),
    courseIsSelected: false,
    instructor_ids: []
  };
  return Object.assign({}, initialValues);
}

let BottomButtons: any;

class CourseHours extends Component<Types.IExamPeriod_ActivityProps, Types.IExamPeriod_Activity_TabState> {
  state: Types.IExamPeriod_Activity_TabState = getInitialState();

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        Logger.error(e as string);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    moment.locale('tr');
    /*let regexObject = window.location.pathname.match(/([^\/]+$)/); // gets the id of the calendar
    let id = regexObject ? parseInt(regexObject[0], 10) : undefined;*/
    var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
    let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
    this.state.model.term_id = id;
    this.state.filters.term_id = id;
    this.setState(this.state);
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      !this.state.model.is_calendar_created &&
      this.state.model.calendar.days.length &&
      this.state.model.calendar.times.length &&
      Object.keys(this.state.model.calendar.schedule).length
    ) {
      this.createModifiedSchedule();
    }
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  getCourseHours() {
    this.props.dispatch(
      Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_GET_COURSETERM_COURSE_HOURS, this.props.filters, 'ep-course-list')
    );
  }

  switchAddModalStatus = () => {
    this.setState({
      ...this.state,
      model: {
        ...this.state.model,
        addModalIsOpen: !this.state.model.addModalIsOpen
      }
    });
  };

  switchClassroomModalStatus = () => {
    this.setState({
      ...this.state,
      model: {
        ...this.state.model,
        classroomAddModalIsOpen: !this.state.model.classroomAddModalIsOpen
      }
    });
  };

  switchDisplay = () => {
    if (this.state.model.displayListPage == false) {
      this.state.model.selected_Activity = {
        term_id: undefined,
        activity_no: 0,
        status: 0,
        status_classroom_assignment: 0,
        attached_classrooms: [],
        time_slots: {},
        course_code: '',
        course_name: ''
      };
    }
    this.setState({
      ...this.state,
      model: {
        ...this.state.model,
        displayListPage: !this.state.model.displayListPage
      }
    });
  };

  createModifiedSchedule() {
    let scheduleClone: any = Object.assign({}, this.state.model.calendar.schedule);
    let daysClone: any = Object.assign([], this.state.model.calendar.days);
    let test_schedule: any = {};

    daysClone.map((day: any) => {
      let newArray: any = [];
      scheduleClone[day].map((item: any) => {
        let obj = {
          hour: item.hour,
          status: item.isActive == true ? 'empty' : 'disabled'
        };
        newArray.push(obj);
      });
      test_schedule[day] = newArray;
    });
    this.setState({
      ...this.state,
      model: {
        ...this.state.model,
        calendar: {
          ...this.state.model.calendar,
          schedule: test_schedule
        },
        initialSchedule: test_schedule,
        is_calendar_created: true
      }
    });
  }

  convertDataToSchedule() {
    let data_time_slots: any =
      this.state.model.selected_Activity && this.state.model.selected_Activity.time_slots
        ? this.state.model.selected_Activity.time_slots
        : {};

    let timeSlotsDays = Object.keys(data_time_slots);
    let dateObject_timeSlots: any = {};
    let result_timeSlots: any = {};
    let scheduleClone: any = this.state.model.calendar.schedule;

    if (timeSlotsDays && !timeSlotsDays.length) {
      this.setState({
        ...this.state,
        model: {
          ...this.state.model,
          calendar: {
            ...this.state.model.calendar,
            schedule: this.state.model.initialSchedule ? this.state.model.initialSchedule : {}
          }
        }
      });
    }

    if (timeSlotsDays && timeSlotsDays.length) {
      timeSlotsDays.map((day: string) => {
        dateObject_timeSlots = scheduleClone[day];
        data_time_slots[day].map((dataHour: string) => {
          dateObject_timeSlots = dateObject_timeSlots.map((item: { hour: string; status: any }) =>
            item.hour == dataHour ? { hour: item.hour, status: 'course' } : item
          );
        });
        result_timeSlots = Object.assign(scheduleClone, result_timeSlots, {
          [day]: dateObject_timeSlots
        });
      });

      setTimeout(() => {
        this.setState({
          ...this.state,
          model: {
            ...this.state.model,
            calendar: {
              ...this.state.model.calendar,
              schedule: result_timeSlots
            }
          }
        });
      }, 0);
    }
  }
  saveCourseHours(addType: string) {
    let dataModel: Types.IExamPeriod_ActivityDataModel = Object.assign({}, ActivityHoursDataModelValues);
    let scheduleClone: any = this.state.model.calendar.schedule;
    let timeSlotsArray: any = [];
    let timesHelperObject: any = {};
    let result_timeSlots: any = {};
    let selectedSlotCount: number = 0;

    this.state &&
      this.state.model.calendar.days.map((day: string) => {
        scheduleClone[day].map((item: { hour: string; status: string }) => {
          if (item.status == 'course') {
            selectedSlotCount++;
            timeSlotsArray.push(item.hour);
          }
        });
        result_timeSlots = Object.assign(timesHelperObject, timeSlotsArray.length ? { [day]: timeSlotsArray } : {});
        timeSlotsArray = [];
      });

    let result_course_hour_ids: Array<number>;
    if (this.props.filters && this.props.filters.select_all) {
      result_course_hour_ids = this.state.model.checked_course_hour_id_list || []
    } else {
      result_course_hour_ids = this.props!.activityList!.filter(i =>
        (this.state.model!.checked_list!).some(filter => filter == i.course_id!)).map(a => a.course_hour_id!)
    }

    if (addType == 'single') {
      dataModel = {
        term_id: this.state.model.term_id,
        course_hour_id: this.state.model.selected_Activity && this.state.model.selected_Activity.course_hour_id,
        //course_id: this.state.model.selected_Activity ? (this.state.model.selected_Activity.activityInfo && this.state.model.selected_Activity.activityInfo.activity_no) : -1,
        time_slots: result_timeSlots
      };
    } else if (addType == 'multiple') {
      dataModel = {
        term_id: this.state.model.term_id,
        course_hour_ids: this.state.model.checked_course_hour_id_list,
        time_slots: result_timeSlots
      };
    }

    let activity_lesson_count = this.state.model && this.state.model.selected_Activity && this.state.model.selected_Activity.activityInfo && this.state.model.selected_Activity.activityInfo.lesson_count ?
      this.state.model && this.state.model.selected_Activity && this.state.model.selected_Activity.activityInfo && this.state.model.selected_Activity.activityInfo.lesson_count : 0;

    if ((selectedSlotCount) < activity_lesson_count && addType == 'single') {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_error'),
          body: (T.t('gen_selected_slot_count_activity_lesson_count_error_message').replace('{0}', (selectedSlotCount)).replace('{1}', activity_lesson_count)),
          name: 'user_import_error',
          icon: 'error_outline',
          iconColor: 'red'
        })
      );
    } else {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_save_hours_title'),
          body: <div className="row">
            <div className="row">
              {T.t('gen_selected_course_count') + ' : ' + (this.state.model.checked_course_hour_id_list.length > 0 ? this.state.model.checked_course_hour_id_list.length : 1)}
            </div>
            <div className="row">
              {T.t('gen_save_hours_question')}
            </div>
          </div>,
          name: 'courseHours_save',
          icon: 'warning',
          iconColor: 'red',
          confirm: T.t('gen_save'),
          cancel: T.t('gen_cancel'),
          onConfirm: () => {
            const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
              if (status == 200) {
                this.state.model.checked_list = [];
                this.state.filters.select_all = false;
                this.setState(this.state);
                this.getCourseHours();
              }
            };

            if (addType == 'single') {
              this.props.dispatch(
                Actions.ApiRequest(
                  Constants.course_period.COURSE_TERM_HOURS_UPDATE,
                  dataModel,
                  'exam-period-modal-tab-spin',
                  resultCallback
                )
              );
            } else if (addType == 'multiple') {
              this.props.dispatch(
                Actions.ApiRequest(
                  Constants.exam_period.EXAM_PERIOD_UPDATE_MULTIPLE_COURSE_HOURS,
                  dataModel,
                  'exam-period-modal-tab-spin',
                  resultCallback
                )
              );
            }
          }
        })
      );
    }
  }

  chooseHour = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e && e.currentTarget) {
      let scheduleClone: any = this.state.model.calendar.schedule;
      const hour: string = e.currentTarget.dataset.hour || '';
      const day: string = e.currentTarget.dataset.day || '';
      let newSchedule = scheduleClone[day].map((item: any) =>
        item.hour == hour && item.status != "disabled"
          ? {
            ...item,
            status: item.status == this.state.model.addType ? 'empty' : this.state.model.addType
          }
          : item
      );
      let updatedSchedule = Object.assign({}, this.state.model.calendar.schedule, {
        [day]: newSchedule
      });
      this.state.model.calendar.schedule = updatedSchedule;
      this.setState(this.state);
    }
  };

  chooseAllHoursOfDay = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e && e.currentTarget) {
      let scheduleClone: any = this.state.model.calendar.schedule;
      const day: string = e.currentTarget.dataset.day || '';

      var checkArray = scheduleClone[day].map((item: any) => item.status);

      let allEmptySlotsAreSelected: boolean = checkArray.includes('course') && checkArray.indexOf('empty') == -1;

      let newSchedule = scheduleClone[day].map((item: any) => {
        if (item.status != 'disabled') {
          if (allEmptySlotsAreSelected) {
            return { ...item, status: 'empty' };
          } else {
            return { ...item, status: 'course' };
          }
        } else return item;
      });
      let updatedSchedule = Object.assign({}, this.state.model.calendar.schedule, {
        [day]: newSchedule
      });
      this.state.model.calendar.schedule = updatedSchedule;
      this.setState(this.state);
    }
  };

  checkAllSelected = (): boolean => {
    const all_ids = this.state.model.all_ids ? this.state.model.all_ids : [];
    const selected_ids = this.state.model.checked_list ? this.state.model.checked_list : [];
    let result: boolean = false;
    if (all_ids.length && selected_ids.length) {
      result = all_ids.every((item: number) => selected_ids.indexOf(item) !== -1);
    }
    return result;
  };

  deleteCourses = (e: React.MouseEvent<HTMLSpanElement>) => {
    if (e && e.target) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_courses_remove_planning'),
          body: T.t('gen_remove_course_from_exam_schedule_question'),
          name: 'examPeriod_courses_delete',
          icon: 'warning',
          iconColor: 'red',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_cancel'),
          onConfirm: () => {
            const resultCallback = (result: Response, status: number) => {
              if (status == 200) {
                this.state.model.checked_list = [];
                this.state.filters.delete_list = [];
                this.setState(this.state);
                this.getCourseHours();
              }
            };
            this.state.filters.delete_list = this.state.model.checked_list;

            this.props.dispatch(
              Actions.ApiRequest(
                Constants.exam_period.EXAM_PERIOD_DELETE_COURSES_FROM_PERIOD,
                this.state.filters,
                'ep-course-list',
                resultCallback
              )
            );
          }
        })
      );
    }
  };

  onPageChange = (page: number) => {
    this.state.filters.page = page;
    this.setState(this.state);
    this.getCourseHours();
  };

  chooseAllDaysofHour = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e && e.currentTarget) {
      let newSchedule = {};
      let scheduleClone: any = this.state.model.calendar.schedule;
      let daysClone: any = this.state.model.calendar.days;
      const time: string = e.currentTarget.dataset.time || '';

      let checkArray: any = [];
      daysClone.map((day: string) =>
        scheduleClone[day].map((item: any) => (item.hour == time ? checkArray.push(item.status) : null))
      );

      let allEmptySlotsAreSelected: boolean = checkArray.includes('course') && checkArray.indexOf('empty') == -1;

      daysClone.map((day: string) => {
        let dayObject = scheduleClone[day].map((item: any) => {
          if (item.hour == time) {
            if (allEmptySlotsAreSelected) {
              return item.status != 'disabled' ? { ...item, status: 'empty' } : item;
            } else {
              return item.status != 'disabled' ? { ...item, status: 'course' } : item;
            }
          } else return item;
        });
        newSchedule = Object.assign(newSchedule, { [day]: dayObject });
      });
      this.state.model.calendar.schedule = JSON.parse(JSON.stringify(newSchedule));
      this.setState(this.state);
    }
  };

  clearAllHours = (e: React.MouseEvent<HTMLButtonElement>) => {
    this.props.dispatch(
      Actions.ShowModal({
        title: T.t('gen_tablo_clear_info'),
        body: T.t('gen_clear_hours_warning'),
        name: 'examPeriod_delete',
        icon: 'error_outline',
        iconColor: 'red',
        confirm: T.t('gen_clear'),
        cancel: T.t('gen_cancel'),
        onConfirm: () => {
          if (e && e.currentTarget) {
            let newSchedule = {};
            let scheduleClone: any = this.state.model.calendar.schedule;
            let daysClone: any = this.state.model.calendar.days;
            daysClone.map((day: string) => {
              let dayObject = scheduleClone[day].map((item: any) => Object.assign({}, item, { isActive: false }));
              newSchedule = Object.assign(newSchedule, {
                [day]: dayObject
              });
            });
            let schedule = newSchedule;
            let updatedSchedule = Object.assign({}, this.state.model.calendar.schedule, schedule);
            this.state.model.calendar.schedule = updatedSchedule;
            this.setState(this.state);
          }
        }
      })
    );
  };

  checkScheduleAndSave = (e: React.MouseEvent<HTMLButtonElement>) => {
    let addType: string = e.currentTarget.dataset.addtype || '';
    let dayHasAnyTrueValues: any = [];
    if (e && e.currentTarget) {
      let scheduleClone: any = this.state.model.calendar.schedule;
      let daysClone: any = this.state.model.calendar.days;
      dayHasAnyTrueValues = daysClone.map((day: string) => {
        return scheduleClone[day].map((item: any) => (item.status == 'course' ? 1 : 0)).includes(1);
      });
      if (!dayHasAnyTrueValues.includes(true)) {
        this.props.dispatch(
          Actions.ShowModal({
            title: 'Saat seçmediniz!',
            body: T.t('gen_select_hours_before_saving'),
            name: 'examPeriod_delete',
            icon: 'error_outline',
            iconColor: 'red',
            cancel: T.t('gen_close')
          })
        );
      } else if (dayHasAnyTrueValues.includes(true)) {
        this.saveCourseHours(addType);
      }
    }
  };

  onCheckCourse = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      let checkedList: any = this.state.model.checked_list;
      let checked_courses_ids: any = this.state.model.checked_courses_ids;

      let selectedListItems: any = this.state.model.selected_list_items;

      let newList = Object.assign([], checkedList);
      let newChecked_courses_ids = Object.assign([], checked_courses_ids)

      let newSelectedListItems = Object.assign([], selectedListItems);

      const stringID: string = e.currentTarget.dataset.id || '';
      const courseID: string = e.currentTarget.dataset.course_id || '';

      let string_courseItem: string = e.currentTarget.dataset.item || '';
      let courseItem: Types.IExamPeriod_ActivityItem = JSON.parse(string_courseItem);

      // if (this.isActiveTab('hours')) {
      //   let course_id=parseInt(courseID,10);
      //   if (checkedList.findIndex((_id: number) => _id === course_id) === -1) {
      //     newList.push(course_id);
      //   } else {
      //     newList = checkedList.filter((item: number) => item != course_id);
      //   }

      //   if(selectedListItems.findIndex((item: Types.IExamPeriod_ActivityItem) => item.course_hour_id === courseItem.course_hour_id) === -1){
      //     newSelectedListItems.push(courseItem)
      //   }else{
      //     newSelectedListItems=selectedListItems.filter((item: Types.IExamPeriod_ActivityItem)=>item.course_hour_id!=courseItem.course_hour_id)
      //   }
      //   this.state.model.checked_list = newList;
      //   this.state.model.selected_list_items=newSelectedListItems;
      // }else{

      // }

      let id = parseInt(stringID, 10);
      if (checkedList.findIndex((_id: number) => _id === id) === -1) {
        newList.push(id);
      } else {
        newList = checkedList.filter((item: number) => item != id);
      }
      this.state.model.checked_list = newList;


      var course_id = parseInt(courseID, 10);
      if (checked_courses_ids.findIndex((id: number) => course_id === id) === -1) {
        newChecked_courses_ids.push(course_id)
      } else {
        newChecked_courses_ids = checked_courses_ids.filter((item: number) => item != course_id)
      }
      this.state.model.checked_courses_ids = newChecked_courses_ids


      if (this.isActiveTab('classrooms')) {
        this.selectActiveTab('classrooms', {
          term_id: undefined,
          page: 1,
          total: -1,
          course_ids: newList,
          size: 10
        });
      }

      if (this.isActiveTab('overlaps')) {
        this.selectActiveTab('overlaps', {
          term_id: undefined,
          page: 1,
          total: -1,
          course_ids: newList,
          size: 10
        });
      }

      this.setState(this.state);
    }
  };

  onUpdateCheckedList = (selected_course_hour_ids: any) => {
    this.state.model.checked_list = (selected_course_hour_ids.length == 0 ? [] : selected_course_hour_ids)
    this.setState(this.state);
  };

  onUpdateCourseHourIdList = (selected_course_hour_ids: any) => {
    this.state.model.checked_course_hour_id_list = (selected_course_hour_ids.length == 0 ? [] : selected_course_hour_ids)
    this.setState(this.state);
  };

  onSaveAllCheckboxes = (selected_ids: any) => {
    this.state.model.all_ids = (selected_ids.length == 0 ? [] : selected_ids)
    this.setState(this.state);
  };

  onSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      if (e.currentTarget.checked) {
        this.state.filters.select_all = true;
        this.setState(this.state);
        this.props.dispatch(
          Actions.ApiRequest(
            Constants.exam_period.EXAM_PERIOD_GET_COURSETERM_COURSE_HOURS,
            this.state.filters,
            'ep-course-list',
            (response: any) =>
              this.setState({
                ...this.state,
                model: {
                  ...this.state.model,
                  checked_list: response.all_ids
                }
              })
          )
        );
      } else {
        this.setState({
          ...this.state,
          model: {
            ...this.state.model,
            checked_list: []
          },
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
    }
  };

  handleAllChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked && this.props.activityList) {
      this.state.model.activity_list.map((course) => {
        if (course.activity_no && !this.state.model.checked_list.find((i) => i === course.activity_no)) {
          this.state.model.checked_list.push(course.activity_no);
        }
      });
      this.state.model.all_checked = true;
    } else if (!e.target.checked && this.state.model.activity_list) {
      this.state.model.activity_list.map((course) => {
        var index = this.state.model.checked_list.indexOf(course.activity_no);
        if (index !== -1) {
          this.state.model.checked_list.splice(index, 1);
        }
      });
      this.state.model.all_checked = false;
    }
    this.setState(this.state);
  };

  onSelectCourse = (e?: React.MouseEvent<HTMLDivElement> | React.MouseEvent<HTMLButtonElement>, passedID?: number) => {
    const id: string = (e && e.currentTarget.dataset.id) || '';
    let numID = parseInt(id, 10);
    // ID bize CourseListPage'deki tablodan gelebilir ya da takvimin olduğu tabloda soldaki listeden gelebilir.
    let theID = passedID && passedID !== -1 ? passedID : numID;
    const selectedCourse = this.state.model.activity_list.find((item) => (item.activityInfo != undefined ? item.activityInfo.activity_no === theID : item.activity_no === theID));
    if (this.state.model.selected_Activity && this.state.model.selected_Activity.activity_no != theID) {
      this.state.model.selected_Activity = selectedCourse;
      this.state.model.calendar.schedule = this.state.model.initialSchedule
        ? JSON.parse(JSON.stringify(this.state.model.initialSchedule))
        : {};
    }
    this.convertDataToSchedule();

    this.setState(this.state);
  };

  selectActiveTab = (tabName: string, filters: any = undefined) => {
    let tabs =
      this.state.model.tabManager &&
      this.state.model.tabManager.map((tab) => {
        return tab.key == tabName ? { key: tab.key, value: true } : { key: tab.key, value: false };
      });

    let activeTab = tabs && tabs.find((item) => item.value == true);
    let tabConstant = activeTab && activeTab.key;

    this.state.model.tabManager = tabs;
    this.state.filters.course_id =
      this.state.model.selected_Activity && (this.state.model.selected_Activity.activityInfo && this.state.model.selected_Activity.activityInfo.activity_no)
        ? (this.state.model.selected_Activity.activityInfo && this.state.model.selected_Activity.activityInfo.activity_no)
        : -1;
    this.setState(this.state);


    if (tabConstant == 'classrooms') {
      let classroomFilter: Types.IFilterClassroom =
        filters !== undefined
          ? filters
          : {
            term_id: undefined,
            page: 1,
            total: -1,
            course_ids: [],
            size: 10
          };

      /*const selectedCourseId =
        this.state.model.selected_Activity && (this.state.model.selected_Activity.activityInfo && this.state.model.selected_Activity.activityInfo.activity_no)
          ? (this.state.model.selected_Activity.activityInfo && this.state.model.selected_Activity.activityInfo.activity_no)
          : -1;*/
      classroomFilter.term_id = this.state.model.term_id;

      if (classroomFilter.course_ids && this.state.model.checked_courses_ids.length) {
        classroomFilter.course_ids = [this.state.filters.course_id];
      }
      // classroomFilter.course_id =
      //   this.state.model.selected_Course && this.state.model.selected_Course.course_id
      //     ? this.state.model.selected_Course.course_id
      //     : -1;
      if (this.state.model.checked_courses_ids.length) {
        this.props.dispatch(
          Actions.ApiRequest(
            Constants.course_period.COURSE_TERM_ATTACHED_CLASSROOM,
            classroomFilter,
            'course-tab-attached-classrooms'
          )
        );
      }
    }

    if (tabConstant == 'overlaps') {
      if (this.state.model.selected_Activity && (this.state.model.selected_Activity.course_id !== null || this.state.model.selected_Activity.course_id !== undefined)) {
        let overlapFilter: Types.IFilterClassroom = this.props.filters !== undefined
          ? JSON.parse(JSON.stringify(this.props.filters))
          : {
            term_id: undefined,
            page: 1,
            total: -1,
            course_ids: [],
            size: 10
          };
        overlapFilter.term_id = this.state.model.term_id;
        overlapFilter.course_id = this.state.filters && this.state.filters.course_id ? this.state.filters.course_id : 0;
        this.state.courseIsSelected = true;
        this.props.dispatch(
          Actions.ApiRequest(
            Constants.exam_period.EXAM_PERIOD_COURSETERM_GET_OVERLAPS,
            overlapFilter,
            'course-tab-overlaps'
          )
        );
        if (this.state.filters && this.state.filters.total < 1) {
          this.state.filters.total = this.props.filters && this.props.filters.total ? this.props.filters.total : -1;
          this.state.filters.page = this.props.filters && this.props.filters.page ? this.props.filters.page : 1;
          this.setState(this.state);
        }
      }
    }
  };

  isActiveTab = (tabName: string) => {
    let activeTab = this.state.model.tabManager && this.state.model.tabManager.find((item) => item.key == tabName);
    if (activeTab) {
      return activeTab.value;
    } else return false;
  };

  //TODO: getDerivedStateFromProps
  static getDerivedStateFromProps(props: Types.IExamPeriod_ActivityProps, state: Types.IExamPeriod_Activity_TabState) {
    let hasNewState: boolean = false;
    if (props.activityList && props.activityList.length) {
      hasNewState = true;
      state.model.activity_list = props.activityList;
      if (
        props.storedCalendar &&
        props.storedCalendar.times &&
        props.storedCalendar.days &&
        Object.keys(props.storedCalendar.schedule).length &&
        !state.model.is_calendar_created
      ) {
        state.model.calendar = props.storedCalendar;
      }
    }

    if (props.all_ids && props.all_ids.length) {
      hasNewState = true;
      state.model.all_ids = props.all_ids;
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  toggleCompactTable = () => {
    this.state.model.showAsCompactTable = !this.state.model.showAsCompactTable;
    this.setState(this.state);
  };

  toggleAddModal = () => {
    this.state.model.addModalIsOpen = !this.state.model.addModalIsOpen;
    this.setState(this.state);
  };

  clearCheckedItems = () => {
    this.state.model.checked_list = [];
    this.state.model.selected_Activity = {
      term_id: undefined,
      activity_no: 0,
      status: 0,
      status_classroom_assignment: 0,
      time_slots: {},
      attached_classrooms: [],
      course_name: '',
      course_code: ''
    };
    this.setState(this.state);
  };

  isSaveButtonShow = () => {
    if (isUserEquals(this.props.user, instructorRoles)) {
      return this.props.general_settings && this.props.general_settings.restrict_hours_type_instructors !== 1
    }
    if (isUserEquals(this.props.user, managerRoles)) {
      return this.props.general_settings && this.props.general_settings.restrict_hours_type_program_managers !== 1
    }
    return true
  }

  render() {
    let { activity_list, checked_list, selected_Activity } = this.state.model;
    let { times, days } = this.state.model.calendar;

    let course_list_ids = activity_list.map((person) => (person.activityInfo && person.activityInfo.activity_no));
    //let checkAllChecked: boolean = course_list_ids.every((id: number) => checked_list.includes(id));
    let tableColumnHeads = days.map((day) => (
      <th key={day}>
        <div data-day={day} onClick={this.chooseAllHoursOfDay}>
          {Days(T).map(item => item.value === day ? T.t(item.label) : '')}
        </div>
      </th>
    ));
    let timeTableRows = null;

    timeTableRows = times.map((time: string, index) => {
      let boxes = Array(days.length)
        .fill(undefined)
        .map((val, index) => {
          let day: string = days[index];
          let scheduleClone: any = this.state && this.state.model.calendar && this.state.model.calendar.schedule;
          return (
            <td key={index}>
              <div
                data-day={day}
                data-hour={time}
                onClick={this.chooseHour}
                className={
                  scheduleClone[day].find((item: any) => item.hour == time).status == "disabled"
                    ? 'unavailable'
                    : scheduleClone[day].find((item: any) => item.hour == time).status == "course"
                      ? 'on'
                      : ''
                }
              ></div>
            </td>
          );
        });

      return (
        <tr key={index}>
          <td className="aplan-tm-sticky-col">
            <div data-time={time} onClick={this.chooseAllDaysofHour}>
              {time}
            </div>
          </td>
          {boxes}
        </tr>
      );
    });

    let CoursePage =
      this.props.calledFromCourseTab === true ? (
        <div className="white-container mt-4">
          <div className="row">
            <div className="col-xl-12 col-lg-8 col-md-12 courses">
              <CourseHoursListPage
                onShowModal={this.switchAddModalStatus}
                onShowClassroomAddModal={this.switchClassroomModalStatus}
                onSwitch={this.switchDisplay}
                onSelect={this.onSelectCourse}
                onCheck={this.onCheckCourse}
                selectedActivityNo={
                  this.state.model.selected_Activity && (this.state.model.selected_Activity.activityInfo && this.state.model.selected_Activity.activityInfo.activity_no)
                }
                checkedActivityNos={this.state.model.checked_list}
              />
            </div>
            <CourseAddModal
              modalIsOpen={this.state.model.addModalIsOpen}
              onClose={this.switchAddModalStatus}
              onUpdateList={() => this.getCourseHours()}
            />
          </div>
        </div>
      ) : (

        <div className="row">
          <div className={'col-xl-6 col-lg-6 col-md-6'}>
            <Spinner name="ep-course-list" />
            <div className="generic-wrapper">
              <div className="row">
                <div className="col-12">
                  <div className="tab-content left-bar-content" id="nav-tabContent4">
                    <div
                      className="tab-pane fade show active"
                      id="action"
                      role="tabpanel"
                      aria-labelledby="action-tab"
                    >
                      <CourseHoursListPage
                        compactTable
                        toggleCompactTable={this.toggleCompactTable}
                        toggleAddModal={this.toggleAddModal}
                        onCheck={this.onCheckCourse}
                        onSelect={this.onSelectCourse}
                        selectedActivityNo={
                          this.state.model.selected_Activity && (this.state.model.selected_Activity.activityInfo && this.state.model.selected_Activity.activityInfo.activity_no)
                        }
                        checkedActivityNos={this.state.model.checked_list}
                        clearCheckedItems={this.clearCheckedItems}
                        updateCheckedList={this.onUpdateCheckedList}
                        updateCourseHourIdList={this.onUpdateCourseHourIdList}
                        saveAllCheckboxes={this.onSaveAllCheckboxes}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={cn(
            "tw-sticky tw-top-20 tw-h-fit",
            'col-xl-6 col-lg-6 col-md-6'
          )}>
            <div className="generic-wrapper">
              {Object.keys(this.state.model.calendar.schedule).length !== 0 &&
                this.state.model.selected_Activity
                && (this.state.model.selected_Activity.activityInfo && this.state.model.selected_Activity.activityInfo.activity_no) ? (
                <React.Fragment>
                  <nav className="inner-page-nav">
                    <div className="nav nav-tabs nav-fill mb-3" id="nav-tab2" role="tablist">
                      <a
                        className={this.isActiveTab('hours') ? 'nav-item nav-link active show' : 'nav-item nav-link'}
                        onClick={() => this.selectActiveTab('hours')}
                        id="course-hours-tab"
                        data-toggle="tab"
                        href="#course-hours"
                        role="tab"
                        aria-controls="nav-home"
                        aria-selected="false"
                      >
                        {T.t('gen_hours')}
                      </a>
                      <a
                        className={
                          this.isActiveTab('classrooms') ? 'nav-item nav-link active show' : 'nav-item nav-link'
                        }
                        onClick={() => this.selectActiveTab('classrooms')}
                        id="attached-classrooms-tab"
                        data-toggle="tab"
                        href="#attached-classrooms"
                        role="tab"
                        aria-controls="nav-home"
                        aria-selected="true"
                      >
                        {T.t('gen_classrooms')}
                      </a>
                      {this.props.user && this.props.user.role !== 'e' && this.props.user.role !== 'p' ? (
                        <a
                          className={
                            this.isActiveTab('overlaps') ? 'nav-item nav-link active show' : 'nav-item nav-link'
                          }
                          onClick={() => this.selectActiveTab('overlaps')}
                          id="overlaps-tab"
                          data-toggle="tab"
                          href="#overlaps"
                          role="tab"
                          aria-controls="nav-home"
                          aria-selected="true"
                        >
                          {T.t('gen_intersections')}
                        </a>
                      ) : null}
                    </div>
                  </nav>
                  <Spinner name="course-tab-attached-classrooms" />
                  <div className="tab-content" id="nav-tabContent2">
                    <div
                      className={this.isActiveTab('hours') ? 'tab-pane fade show active' : 'tab-pane fade'}
                      id="course-hours"
                      role="tabpanel"
                      aria-labelledby="course-hours-tab"
                    >
                      <div className="row">
                        <div className="col-12">
                          <div className="aplan-table-matrix-wrapper">
                            <div
                              className="aplan-table-matrix-scroller"
                              style={{
                                height: '100%'
                              }}
                            >
                              <button
                                className="category-tag-square tag-glass"
                                style={{ margin: '5px' }}
                                onClick={() => {
                                  this.state.model.detailIsOpen = !this.state.model.detailIsOpen;
                                  this.setState(this.state);
                                }}
                              >
                                <i className="material-icons mr-2">filter_list</i>
                                ({GT.DistinctTextArray(this.state.model.selected_Activity!.activityInfo!.course_code!.split(';')).join(', ')})&nbsp;{GT.DistinctTextArray(this.state.model.selected_Activity!.activityInfo && this.state.model.selected_Activity.activityInfo.course_name!.split(';')).join(', ')}
                              </button>
                              <div
                                className={`mt-4 collapse ` + (this.state.model.detailIsOpen ? `show` : ``)}
                                style={{ marginLeft: '-13px' }}
                              >
                                <CourseHourDetailTable
                                  detail={this.state.model.selected_Activity!.activityInfo!}
                                />
                              </div>
                              <table className="table table-borderless table-striped table-hover aplan-table-matrix course-table mb-0">
                                <thead>
                                  <tr>
                                    <th className="aplan-tm-sticky-col">
                                      <div>#</div>
                                    </th>
                                    {tableColumnHeads}
                                  </tr>
                                </thead>
                                <tbody>{timeTableRows}</tbody>
                              </table>
                            </div>
                          </div>
                          <div>
                            {
                              this.state.model.checked_course_hour_id_list && this.state.model.checked_course_hour_id_list.length > 1 ? (
                                this.props.user && (this.props.user.role === 's' || this.props.user.role === 'a' || this.props.user.role === 'e' || this.props.user.role === 'p') && this.isSaveButtonShow() ? (
                                  <button
                                    type="button"
                                    className="btn btn-blue mt-2 mb-2 float-right"
                                    data-addtype="multiple"
                                    onClick={this.checkScheduleAndSave}
                                  >
                                    <i className="material-icons mr-2 text-left">done_all</i>
                                    <span>{T.t('gen_save_multi')}</span>
                                    <b>
                                      &nbsp;(
                                      {this.state.model.checked_course_hour_id_list.length})
                                    </b>
                                  </button>
                                ) : null
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-green mt-2 mb-2 mr-2 float-right"
                                  data-addtype="single"
                                  disabled={this.isSaveDisabled()}
                                  onClick={this.checkScheduleAndSave}
                                >
                                  <i className="material-icons mr-2 text-left">done</i>
                                  <span>{T.t('gen_save')}</span>
                                  <b>
                                    &nbsp;(
                                    {this.state.model.checked_course_hour_id_list.length === 0 ? 1 : this.state.model.checked_course_hour_id_list.length})
                                  </b>
                                </button>
                              )
                            }
                          </div>
                          <button
                            type="button"
                            className="btn btn-gray mt-2 mb-2 float-none"
                            onClick={() => {
                              this.state.model.isExpanded = !this.state.model.isExpanded;
                              this.setState(this.state);
                            }}
                          >
                            <i className="material-icons mr-2 text-left">
                              {this.state.model.isExpanded ? 'remove' : 'add'}
                            </i>
                            <span>{this.state.model.isExpanded ? T.t('gen_collapse') : T.t('gen_expand')}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div
                      className={this.isActiveTab('classrooms') ? 'tab-pane fade show active' : 'tab-pane fade'}
                      id="attached-classrooms"
                      role="tabpanel"
                      aria-labelledby="attached-classrooms-tab"
                    >
                      <div >
                        <ClassroomListPage
                          calledFromCourseTab={true}
                          calledFrom="course-tab"
                          onUpdateList={(filters: any) => this.selectActiveTab('classrooms', filters)}
                          selectedCourseID={
                            this.state.model.selected_Activity && (this.state.model.selected_Activity.activityInfo && this.state.model.selected_Activity.activityInfo.activity_no)
                              ? (this.state.model.selected_Activity.activityInfo && this.state.model.selected_Activity.activityInfo.activity_no)
                              : 0
                          }
                          selectedCourseCheckbox={this.state.model.checked_list}
                          onShowClassroomAddModal={this.switchClassroomModalStatus}
                        />
                      </div>
                    </div>
                    <div
                      className={this.isActiveTab('overlaps') ? 'tab-pane fade show active' : 'tab-pane fade'}
                      id="overlaps"
                      role="tabpanel"
                      aria-labelledby="overlaps-tab"
                    >
                      <p>
                        ({this.state.model.selected_Activity!.activityInfo!.course_code})&nbsp;
                        {this.state.model.selected_Activity!.activityInfo!.course_name}
                      </p>
                      {this.isActiveTab('overlaps') ?
                        <Overlaps courseIsSelected={this.state.courseIsSelected} selectedActivityInfo={this.state.model.selected_Activity!.activityInfo} />
                        : null}
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <div>
                  <img
                    style={{
                      display: 'block',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      width: '40%'
                    }}
                    src={Announcement}
                  />
                  <br />
                  <p style={{ textAlign: 'center' }}>
                    {T.t('gen_course_hours_area_info_text')} <br /> {T.t('gen_remember_to_save_hours')}
                  </p>
                </div>
              )}
            </div>
          </div>
          <CourseAddModal
            modalIsOpen={this.state.model.addModalIsOpen}
            onClose={this.switchAddModalStatus}
            onUpdateList={() => this.getCourseHours()}
          />
          <ClassroomAddModal
            modalIsOpen={this.state.model.classroomAddModalIsOpen}
            onClose={this.switchClassroomModalStatus}
            onUpdateList={() => this.selectActiveTab('classrooms')}
            calledFromCourseTab={true}
            term_id={this.state.filters.term_id}
            selectedCourseIDs={
              this.state.model.checked_list
                ? this.state.model.checked_list
                : []
            }
          />
        </div>
      );

    return <React.Fragment>{CoursePage}</React.Fragment>;
  }
  isSaveDisabled(): boolean {
    if (!this.props.user) {
      return true
    }
    if ([UserRole.INSTRUCTOR, UserRole.MANAGER, UserRole.PANEL, UserRole.SYSTEM].includes(this.props.user.role as UserRole)) {
      return !this.isSaveButtonShow()
    }
    return true
  }
}

const mapStateToProps = (
  store: Types.IPersistedState,
  ownProps: Types.IExamPeriod_ActivityProps
): Types.IExamPeriod_ActivityProps => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: Types.IExamPeriod_ActivityProps = Object.assign({}, ownProps, {
    activityList:
      store.state.examPeriodModal &&
      store.state.examPeriodModal.courseterm_course_hours &&
      store.state.examPeriodModal.courseterm_course_hours.activity_list,
    storedCalendar: store.state.examPeriodModal && store.state.examPeriodModal.storedCalendar,
    filters: store.state.examPeriodModal && store.state.examPeriodModal.filters,
    all_ids:
      store.state.examPeriodModal &&
      store.state.examPeriodModal.courseterm_course_hours &&
      store.state.examPeriodModal.courseterm_course_hours.all_ids,
    user: store.state.user,
    general_settings: store.state.general_settings
  });
  return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  /*if (next.state.examPeriodModal) {
    return (
      !!equal(prev.state.examPeriodModal, next.state.examPeriodModal) &&
      !!equal(
        prev.state.examPeriodModal && prev.state.examPeriodModal.courseterm_course_hours,
        next.state.examPeriodModal && next.state.examPeriodModal.courseterm_course_hours
      )
    );
  } else {
    return true;
  }*/ return false;
};

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(CourseHours);

export default container;
