import React, { useEffect, useState, useReducer, useRef, useCallback } from 'react';
import { Log } from 'ng2-logger';
import { IPageProps } from '../../store/types';
import { routes as Routes } from '../../store/constants/routes';
import * as GT from '../../tools/general-tools';
import APlanLogo from '../../assets_aplan/images/plan-365-logo.png';
import coLogo_short from '../../assets/logos/co_short.png';
import coLogo_fullSize from '../../assets/logos/co_fullsize_white.png';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import { SettingsDashboardStatus } from '../../store/constants/dashboard-const';
import { SettingsEventModuleStatus } from '../../store/constants/setting-const';
import SyncJobModal from './sync-job-modal';
import * as Types from '../../store/types';
import Translator from '../../services/translate-factory';
import { Link } from 'react-router-dom';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';
import AppFactory from '../../services/app-factory';
import { connect } from 'react-redux';
import TermSelector from '../term/term-selector';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { NotificationStatuses, NotificationTypes } from '../../store/constants/notification-const';
import { TermType } from '../../store/constants/general';
import cn, { flexIC, flexJB, flexRow } from '../ui/Tailwind';
import { FaSync } from "react-icons/fa";
import DropdownElement, { IDropdownElement, NavItem } from './dropdown-elements';
import { UserRole } from '../../store/constants/enums';
import { dashboardItems, staticDefinitionsItems, syncDefinitionItems, termDefinitionsItems, termsItems } from './header-items';
import { FiLogOut } from "react-icons/fi";
import { MdMenu } from 'react-icons/md';
import { debounce } from 'lodash';
import { useSSE } from '../hooks/SSEContext';

const AF = AppFactory.create();

declare const appSettings: Types.IAppSettings;

const Header: React.FC<IPageProps> = (props) => {
  const T = Translator.create();
  const [syncModalIsOpen, setSyncModalIsOpen] = useState(false);
  const [notificationInventory, setNotificationInventory] = useState<Types.ISSENotificationInventory>({
    notifications: []
  });
  const [wideList, setWideList] = useState<IDropdownElement[]>([]);
  const [hamburgerList, setHamburgerList] = useState<IDropdownElement[]>([]);
  const eventSource = useSSE()
  const [, forceRender] = useReducer(x => x + 1, 0);
  const handleLanguageChange = useCallback(
    debounce(() => {
      forceRender(1);
      handleResize()
    }, 1000),
    []
  );

  useEffect(() => {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, handleLanguageChange);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, handleLanguageChange);
    return () => {
      T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, handleLanguageChange);
      CloseSSE()
    };
  }, [props.term_id]);

  // temporary fix for the header
  const breakpoints = [
    { width: 1510, maxItems: 13 },
    { width: 1374, maxItems: 12 },
    { width: 1321, maxItems: 11 },
    { width: 1218, maxItems: 10 },
    { width: 1133, maxItems: 9 },
    { width: 1062, maxItems: 8 },
    { width: 972, maxItems: 7 },
    { width: 886, maxItems: 6 },
    { width: 790, maxItems: 5 },
    { width: 618, maxItems: 4 },
    { width: 530, maxItems: 3 },
    { width: 370, maxItems: 2 },
    { width: 216, maxItems: 1 },
    { width: 0, maxItems: 0 },
  ];
  const prevWideListRef = useRef<IDropdownElement[] | null>(null);

  const checkPermissions = (permissions: string[] | undefined) => {
    if (permissions) {
      if (!props.user || (props.user && !permissions.includes(props.user.role))) {
        return false;
      }
    }
    return true
  }

  const handleResize = () => {
    const container = document.querySelector('.aplan-header-container');
    const containerWidth = container && container.clientWidth || 0;
    const elements = getDropdownElement();
    const matchingBreakpoint = breakpoints.find(bp => containerWidth >= bp.width);
    const maxItems = matchingBreakpoint ? matchingBreakpoint.maxItems : elements.length;
    let wideItems: IDropdownElement[] = [];
    let hamburgerItems: IDropdownElement[] = [];

    let count = 0;
    elements.filter(
      _ => _.show
        ? _.show(props, {
          eventSource: eventSource,
          notificationInventory: notificationInventory,
          syncModalIsOpen: syncModalIsOpen,
          term_id: props.term_id,
        }) : true)
      .filter(_ => checkPermissions(_.permissions))
      .forEach((element) => {
        if (count < maxItems) {
          wideItems.push(element);
        } else {
          hamburgerItems.push(element);
        }
        count++
      });

    if (JSON.stringify(wideItems) !== JSON.stringify(prevWideListRef.current)) {
      setWideList(wideItems);
      setHamburgerList(hamburgerItems);
      prevWideListRef.current = wideItems;
    }
  };

  useEffect(() => {
    const container = document.querySelector('.aplan-header-container');

    if (!container) return

    handleResize();

    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'attributes' || mutation.type === 'childList') {
          handleResize();
        }
      }
    });

    observer.observe(container, { attributes: true, childList: true, subtree: true });

    window.addEventListener('resize', handleResize);

    // cleanup function
    return () => {
      observer.disconnect();
      window.removeEventListener('resize', handleResize);
    };
  }, [props.term_id]);

  useEffect(() => {
    if (!eventSource) return

    eventSource.addEventListener('notification_inventory', setNI, false);

    return () => {
      eventSource.removeEventListener('notification_inventory', setNI, false);
    }
  }, [eventSource])

  const setNI = (e: any) => {
    if (e.data) {
      const notificationInventory: Types.ISSENotificationInventory = JSON.parse(e.data);
      if (notificationInventory) {
        let notficationInventory = {
          notifications: notificationInventory.notifications ? notificationInventory.notifications : [],
        };
        setNotificationInventory(notficationInventory);
      }
    }
  };

  const CloseSSE = () => {
    if (eventSource) {
      eventSource.close();
    }
  };

  const onLogOut = () => {
    AF.logOff();
  };

  const onGoToPersonalInformations = () => {
    props.dispatch(Actions.Navigation(GT.Route(Routes.USER_SETTINGS)));
  };

  const switchSyncModalStatus = () => {
    setSyncModalIsOpen(!syncModalIsOpen);
  };

  const showDownloadFileModal = (e: React.MouseEvent<HTMLButtonElement>, notification_id: number) => {
    if (e && e.target) {
      props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_download_file_title'),
          body: T.t('gen_download_file_notification_body'),
          name: 'download_file',
          icon: 'info',
          iconColor: 'blue',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_cancel'),
          onConfirm: () => {
            props.dispatch(
              Actions.ApiRequest(Constants.notification.NOTIFICATION_GET_SIGNED_URL_BY_ID, notification_id, '',
                (result: string, status: number) => {
                  if (status === 200) {
                    openLinkInNewTab(result);
                  }
                })
            );
          }
        })
      );
    }
  };

  const notificationCheck = () => {
    return notificationInventory && notificationInventory.notifications && notificationInventory.notifications.length > 0 && !notificationInventory.notifications[0].is_read;
  };

  const headerOptions = () => {
    const languages: Array<Types.ISelectOption> = GT.Languages();

    const accountProfile: NavItem[] = [
      {
        key: 0,
        label: T.t('gen_user_settings'),
        to: onGoToPersonalInformations
      },
      {
        key: 1,
        type: 'divider'
      },
      {
        key: 2,
        label: <span className={cn(flexRow, flexIC, flexJB)}>{T.t('gen_sign_out')}<FiLogOut className='tw-ml-2' /></span>,
        danger: true,
        to: onLogOut,
      },
    ];

    return (
      <div className='header-options tw-hidden md:tw-flex'>
        {notificationCheck() &&
          <Link to={GT.Route(Routes.NOTIFICATIONS)} id='notification_tooltip'>
            <i className="material-icons" style={{ fontSize: 'xx-large' }}>
              circle_notifications
            </i>
            {
              (window.innerWidth > 760) && <div
                id='notification_span'
                className="mss-notification-span"
                style={{ margin: '25px 20px 0px 0px' }}
              >
                <div style={{ marginTop: '-2px' }}>
                  {notificationInventory.notifications!.length}
                </div>
              </div>
              ||
              <div
                id='notification_span'
                className="mss-notification-span"
              >
                <div style={{ marginTop: '-2px' }}>
                  {notificationInventory.notifications!.length}
                </div>
              </div>

            }
            <UncontrolledPopover trigger="hover focus" placement="bottom" target="notification_tooltip">
              <PopoverBody className="scrollable-popoverbody">
                {notificationInventory.notifications && notificationInventory.notifications.map((item: Types.INotificationItem) => {
                  return (
                    <tr key={'notification-' + item.notification_id} data-title={item.description}>
                      <td style={{ width: '15px' }} scope="row" data-label={T.t('gen_status')} className="text-center mr-1">
                        <div className="tags">
                          <button
                            id='button_status'
                            className={
                              `tw-p-1 small-tag text-uppercase` +
                              (item.status == 1 ? ` tag-green` : (item.status == 0 ? ` tag-orange` : ` tag-red`))
                            }
                          >
                            {NotificationStatuses(T).find((x) => x.value === item.status)!.label}
                          </button>
                        </div>
                      </td>
                      <td style={{ minWidth: '95px' }} data-label={T.t('gen_notification_type')} className="text-center mr-1 ml-1">
                        {NotificationTypes(T).find((x) => x.value === item.type)!.label}
                      </td>
                      <td style={{ minWidth: '50px' }} data-label={T.t('gen_description')} className="text-center mr-1 ml-1">
                        {item.description && item.description.length ?
                          <div className="table-scrollable-td">{item.description}</div> : '-'}
                      </td>
                      <td data-label={T.t('gen_download')} className="text-center">
                        {item && item.status == 1 && item.type != 2 ? (
                          <button
                            id='button_download'
                            className="category-tag-square tag-blue text-center ml-2 mt-2"
                            disabled={(item.status !== 1)}
                            onClick={(e) => showDownloadFileModal(e, item.notification_id)}>
                            <i className="material-icons">download</i>
                          </button>
                        ) : ("-")}
                      </td>
                    </tr>
                  );
                })}
              </PopoverBody>
            </UncontrolledPopover>
          </Link>
          || <Link to={GT.Route(Routes.NOTIFICATIONS)}>
            {<i className="material-icons" style={{ fontSize: 'xx-large' }}>
              circle_notifications
            </i>}
          </Link>
        }

        <DropdownElement
          props={props}
          state={{
            eventSource: eventSource,
            notificationInventory: notificationInventory,
            syncModalIsOpen: syncModalIsOpen,
            term_id: props.term_id,
          }}
          arrow={false}
          title={<i title={T.t('gen_header_change_lang')} className="material-icons" style={{ fontSize: 'xx-large' }}>
            language
          </i>}
          disablePadding={true}
          trigger={["click"]}
        >
          {
            languages.map(_ => ({
              key: _.value,
              label: _.label,
              to: () => T.changeLanguage(_.value, true)
            }))
          }
        </DropdownElement>

        <DropdownElement
          props={props}
          state={{
            eventSource: eventSource,
            notificationInventory: notificationInventory,
            syncModalIsOpen: syncModalIsOpen,
            term_id: props.term_id,
          }}
          arrow={false}
          disablePadding={true}
          title={<i className="material-icons" style={{ fontSize: 'xx-large' }}>
            account_circle
          </i>}
          trigger={["click"]}
        >{accountProfile}</DropdownElement>
      </div>
    );
  };

  const openLinkInNewTab = (url: string) => {
    const win = window.open(url, '_blank');
    win && win.focus();
  };

  const getHelpItems = () => {
    return [
      {
        key: 0,
        icon: <i className="material-icons mr-2">help</i>,
        label: T.t("gen_help_guide"),
        to: () => {
          openLinkInNewTab('https://yardim.advancity.com.tr/plan365');
        }
      },
      {
        key: 1,
        icon: <i className="material-icons mr-2">report_problem</i>,
        label: T.t("gen_report_issue"),
        to: () => {
          openLinkInNewTab('https://advancity.atlassian.net/servicedesk/customer/portals');
        }
      },
    ];
  };

  const getSyncItems = () => {
    return [
      {
        key: 0,
        label: T.t('gen_new_sync'),
        to: switchSyncModalStatus,
        icon: <FaSync />,
      },
      {
        key: 1,
        label: T.t('gen_existing_sync_jobs'),
        children: syncDefinitionItems(T)

      },
    ];
  };

  const getDropdownElement = (): Array<IDropdownElement> => {
    return [
      {
        children: dashboardItems(T),
        title: T.t('gen_dashboard'),
        permissions: [UserRole.SYSTEM, UserRole.PANEL, UserRole.PANEL_RESTRICTED, UserRole.EVENT_MANAGER],
        show: (props: IPageProps) => {
          const { general_settings } = props;
          return general_settings && general_settings.dashboard_status > SettingsDashboardStatus.Passive;
        }
      },
      {
        children: getSyncItems(),
        title: T.t('gen_synchronization'),
        permissions: [UserRole.SYSTEM, UserRole.EVENT_MANAGER],
      },
      {
        children: staticDefinitionsItems(T),
        title: T.t('gen_static_definitions'),
        permissions: [UserRole.SYSTEM, UserRole.EVENT_MANAGER, UserRole.EVENT_USER, UserRole.STAFF],
      },
      {
        children: termsItems(T),
        title: T.t('gen_terms'),
      },
      {
        children: termDefinitionsItems(T),
        title: T.t('gen_term_definitions'),
        permissions: [UserRole.SYSTEM, UserRole.MANAGER],
        show: (props, states) => {
          return props.term_type !== TermType.UNSELECTED && props.term_type !== TermType.EVENT;
        }
      },
      {
        arrow: false,
        title: T.t('gen_planning'),
        permissions: [UserRole.SYSTEM, UserRole.MANAGER, UserRole.MANAGER_RESTRICTED, UserRole.INSTRUCTOR, UserRole.INSRUCTOR_RESTRICTED, UserRole.PANEL, UserRole.PANEL_RESTRICTED, UserRole.USER],
        to: GT.Route(
          props.term_type == 0 ? Routes.COURSE_PERIODS : Routes.EXAM_PERIODS,
          '/calendar/' + props.term_id
        ),
        show: (props, state) => {
          return props.term_type !== TermType.UNSELECTED && props.term_type !== TermType.EVENT;
        },
      },
      {
        arrow: false,
        title: T.t('gen_solution'),
        permissions: [UserRole.SYSTEM, UserRole.MANAGER, UserRole.MANAGER_RESTRICTED, UserRole.INSTRUCTOR, UserRole.INSRUCTOR_RESTRICTED, UserRole.PANEL, UserRole.PANEL_RESTRICTED, UserRole.USER],
        to: GT.Route(Routes.SOLUTION, '/solution/' + props.term_id),
        show: (props, state) => {
          return props.term_type !== TermType.UNSELECTED && props.term_type !== TermType.EVENT;
        },
      },
      {
        arrow: false,
        title: T.t('gen_event_calendar'),
        to: GT.Route(Routes.EVENT_PERIODS, '/calendar/' + props.term_id),
        show: (props, state) => {
          const { general_settings } = props;
          return props.term_type === TermType.EVENT &&
            (general_settings && general_settings.event_module_status === SettingsEventModuleStatus.Active);
        },
      },
      {
        arrow: false,
        title: T.t('sketches'),
        to: Routes.SKETCHES,
        show: (props: IPageProps) => {
          const { general_settings } = props;
          return general_settings && general_settings.event_module_status === SettingsEventModuleStatus.Active
        }
      },
      {
        title: T.t('gen_users'),
        arrow: false,
        to: Routes.USERS,
        permissions: [UserRole.SYSTEM, UserRole.EVENT_MANAGER],
      },
      {
        arrow: false,
        title: T.t('gen_settings'),
        permissions: [UserRole.SYSTEM, UserRole.EVENT_MANAGER],
        to: Routes.SETTINGS
      },
      {
        arrow: false,
        title: T.t('gen_backup'),
        to: Routes.BACKUPS,
        permissions: [UserRole.SYSTEM, UserRole.EVENT_MANAGER],
      },
      {
        title: T.t('gen_help'),
        children: getHelpItems(),
      },

      /*
      Development Tab
      {
        title: T.t('gen_development'),
        children: [
          {
            key: 0,
            label: T.t('gen_event_calendar'),
            to: GT.Route(Routes.EVENT_PERIODS, '/calendar/' + '0001'),
          },
        ]
      }
        */
    ];
  };

  return (
    <header style={{ padding: 0 }}>
      <div className="aplan-header">
        <Link to={GT.Route(Routes.DASHBOARD)}
          style={{
            marginRight: '1rem',
          }}
        >
          {
            appSettings.SCHEDULE
              ? <img src={coLogo_fullSize} alt="CT Logo" style={{ width: '120px', height: 'auto', paddingTop: '2px' }} />
              : <img src={APlanLogo} alt="APlan" style={{ width: '100px', height: 'auto' }} />
          }
        </Link>
        <div className={cn("aplan-header-container tw-justify-start", flexRow, flexIC, "tw-gap-1 tw-flex-grow")}>
          {
            wideList && wideList.map(_ =>
              <DropdownElement
                props={props}
                state={{
                  eventSource: eventSource,
                  notificationInventory: notificationInventory,
                  syncModalIsOpen: syncModalIsOpen,
                  term_id: props.term_id,
                }}
                {..._}
              />)
          }

          {hamburgerList.length > 0 && <DropdownElement
            props={props}
            state={{
              eventSource: eventSource,
              notificationInventory: notificationInventory,
              syncModalIsOpen: syncModalIsOpen,
              term_id: props.term_id,
            }}
            arrow={false}
            title={<MdMenu className='tw-text-xl' />}
          >
            {hamburgerList.map(_ =>
            ({
              ..._,
              label: _.title
            } as NavItem)
            )}
          </DropdownElement>}
        </div>
        <div className='tw-flex tw-flex-row tw-pl-2'>
          <TermSelector onLogOut={onLogOut} />
          <div className='tw-w-2' />
          {headerOptions()}
        </div>
      </div>
      {
        syncModalIsOpen ? (
          <SyncJobModal
            modalIsOpen={syncModalIsOpen}
            onClose={switchSyncModalStatus}
          />
        ) : null
      }
    </header>
  );
};

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IPageProps): Types.IPageProps => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: Types.IPageProps = {
    ...ownProps,
    user: store.state.user,
    term_id: store.state.term_id,
    term_type: store.state.term_type,
    general_settings: store.state.general_settings,
  }
  return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps)(Header);

export default container;
