import { Formik, FormikActions, FormikProps } from 'formik';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Modal, PopoverBody, PopoverHeader, UncontrolledPopover } from 'reactstrap';
import * as Constants from '../../../store/constants/all';
import * as Actions from '../../../store/actions/general';
import moment from 'moment';
import Flatpickr from 'react-flatpickr';
import {
  ActivityFormInitialValues,
  DayOfWeek
} from '../../../store/constants/activity-const';
import * as Types from '../../../store/types';
import * as GT from '../../../tools/general-tools';
import { SolutionActivityAddFormVal } from './validations/solution-activity-form-val';
import Translator from '../../../services/translate-factory';
import SolutionActivityAddDetailTable from './add-detail-table';

const T = Translator.create();
const equal = require('deep-equal');
const Logger = Log.create('SolutionActivityAddModal');

class SolutionActivityAddModal extends Component<any, any> {
  state: any = {
    term_id: -1,
    model: Object.assign({}, ActivityFormInitialValues)
  };

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        Logger.error(e as string);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
    let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
    this.state.term_id = id;
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  getActivityAtSolutionAddModal = (activityNo: number) => {
    this.props.dispatch(Actions.ApiRequest(Constants.activity.ACTIVITY_GET_BY_ID, activityNo, 'activity-detail-list-spin'));
  }

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.props.onClose(refresh);
    }
  };

  setCloseModal = () => {
    this.setClose();
  };

  onFormSave = (values: Types.ISolutionActivityAddModal, FormActions: FormikActions<Types.ISolutionActivityAddModal>) => {
    const resultCallback = (result: any, status: number) => {
      if (status === 200) {
        this.props.dispatch(Actions.Notification(result.activity.course_name + ' (' + result.activity.course_code + ') - ' + result.activity.activity_no + ' ' + T.t('notification_lesson_added'), 'gen_success'));
        this.setClose(true);
      }
    };

    let model = {
      term_id: this.props.term_id,
      solution_id: this.props.solutionId,
      week_of_day: values.week_of_day,
      start_hour: values.start_hour,
      activity_no: Number(values.activity_no),
      classroom_id: values.classroom_id,
    }

    this.props.dispatch(
      Actions.ApiRequest(Constants.solution.SOLUTION_ADD_ACTIVITY, model, 'course-form-spin', resultCallback)
    );

    this.props.resultSearchObjectFunction(
      [Number(values.activity_no)],
    );
  }

  static getDerivedStateFromProps(props: any, state: Types.ICoursePageState) {
    let hasNewState: boolean = false;

    if (props && props.examDates) {
      hasNewState = true;
      const endHour = moment(props.examDates.end_hour, 'HH:mm').format('H');
      const duration_hour = moment(props.examDates.slot_duration, 'HH:mm').format('H');
      const max = parseInt(endHour, 10);

      state.minHour = props.examDates.start_hour;
      const maxHour = moment(max - Number(duration_hour), 'H').format('HH:mm');
      state.maxHour = maxHour;
    }

    if (props && (props.activityDetail != state.activityDetail)) {
      hasNewState = true;
      state.activityDetail = props.activityDetail;
    } else {
      state.activityDetail = undefined;
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  render() {
    const formInitialValues = {};
    return (
      <Modal
        className="pt-0"
        style={{ maxWidth: '100%', padding: '0 15px' }}
        isOpen={this.props.modalIsOpen}
        toggle={this.setCloseModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title d-inline-flex align-items-center" id="exampleModalLabel">
              {T.t('gen_solution-activity-add')}
            </h6>
            <button
              id='button_close'
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.setCloseModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid p-0">
              <div className="alert alert-warning" role="alert" style={{ maxWidth: 'none', textAlign: 'center' }}>
                {T.t('gen_solution_add_warning')}
              </div>
              <div className="row">
                <div className="white-container collapse show" id="advance-search" style={{ boxShadow: 'none' }}>
                  <Formik
                    initialValues={formInitialValues}
                    enableReinitialize={true}
                    validationSchema={SolutionActivityAddFormVal}
                    onSubmit={(values, actions) => {
                      this.onFormSave(values, actions);
                    }}
                  >
                    {(props: FormikProps<Types.ISolutionActivityAddModal>) => {
                      const { values, handleChange, errors, handleBlur, handleSubmit, isSubmitting } = props;

                      return (
                        <form onSubmit={handleSubmit}>
                          <div className="row">
                            <div className="col-md-2">
                              <div className="react-select-container">
                                <label>{T.t('gen_day')}</label>
                                <Select
                                  id='select_day'
                                  className="react-select"
                                  isMulti={false}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={true}
                                  options={DayOfWeek(T)}
                                  placeholder={T.t('gen_select_day')}
                                  values={props.values.week_of_day}
                                  value={
                                    props.values.week_of_day != undefined && props.values.week_of_day != null
                                      ? DayOfWeek(T).find((option) => option.value == props.values.week_of_day)
                                      : null
                                  }
                                  onChange={(option: any) => props.setFieldValue('week_of_day', option.value)}
                                  noOptionsMessage={(): string => T.t('gen_select_no_day')}
                                />
                              </div>
                              {errors && errors.week_of_day && props.submitCount > 0 && (
                                <div className="error">{T.t('gen_cannot_leave_empty')}</div>
                              )}
                            </div>
                            <div className="col-md-2 form-input form-group with-icon">
                              <div className="add-custom-tag mt-3">
                                <Flatpickr
                                  id='start_hour'
                                  value={props.values.start_hour}
                                  name="start_hour"
                                  placeholder={T.t('gen_select_time')}
                                  options={{
                                    enableTime: true,
                                    dateFormat: 'H:i',
                                    noCalendar: true,
                                    time_24hr: true,
                                    maxDate: this.state.maxHour ? this.state.maxHour : undefined,
                                    minDate: this.state.minHour ? this.state.minHour : undefined
                                  }}
                                  onClose={(value) => props.setFieldValue('start_hour', moment(value[0]).format('HH:mm'))}
                                />
                                <span className="highlight" />
                                <span className="bar" />
                                <label htmlFor="start-time-datepicker">{T.t('gen_start_time')}</label>
                              </div>
                              {errors && errors.start_hour && props.submitCount > 0 && (
                                <div className="error mt-3">{T.t('gen_cannot_leave_empty')}</div>
                              )}
                            </div>
                            <div className="col-md-3">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_course')}</label>
                                  <Select
                                    id='select_activity'
                                    className="react-select"
                                    isMulti={false}
                                    closeMenuOnSelect={true}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                    }
                                    options={
                                      this.props.solutionActivityAddSelectOptions && this.props.solutionActivityAddSelectOptions.activities
                                        ? this.props.solutionActivityAddSelectOptions.activities
                                        : []
                                    }
                                    placeholder={T.t('gen_select_course')}
                                    value={props.values.activity_noes ? props.values.activity_noes : null}
                                    onChange={(option: any) => {
                                      props.setFieldValue('activity_noes', option);
                                      props.setFieldValue('activity_no', option && option.value);
                                      this.getActivityAtSolutionAddModal(option && option.value)
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_activity')}
                                  />
                                </div>
                                {errors && errors.activity_no && props.submitCount > 0 && (
                                  <div className="error">{T.t('gen_cannot_leave_empty')}</div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_classroom')}</label>
                                  <Select
                                    id='select_classroom'
                                    className="react-select"
                                    isMulti={false}
                                    isDisabled={this.state.activityDetail && this.state.activityDetail.lecture_location_id <= 1002 && this.state.activityDetail.lecture_location_id >= 1000 ? true : false}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                    }
                                    closeMenuOnSelect={true}
                                    options={
                                      this.props.solutionActivityAddSelectOptions && this.props.solutionActivityAddSelectOptions.classrooms
                                        ? this.props.solutionActivityAddSelectOptions.classrooms
                                        : []
                                    }
                                    placeholder={T.t('gen_select_classroom')}
                                    value={props.values.classroom ? props.values.classroom : null}
                                    onChange={(option: any) => {
                                      props.setFieldValue('classroom', option);
                                      props.setFieldValue('classroom_id', option && option.value);
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_classroom')}
                                  />
                                </div>
                                {errors && errors.classroom_id && props.submitCount > 0 && (
                                  <div className="error">{T.t('gen_cannot_leave_empty')}</div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-6"></div>
                            <div className="col-6 text-right">
                              <button
                                id='button_save'
                                type="button"
                                className="btn btn-green mt-md-0 mt-2 mb-md-0 mb-2"
                                onClick={() => {
                                  props.handleSubmit()
                                }}
                              >
                                {T.t('gen_save')}
                              </button>
                            </div>
                          </div>
                          <hr />
                        </form>
                      );
                    }}
                  </Formik>
                </div>
                {this.state.activityDetail && (
                  <SolutionActivityAddDetailTable
                    detail={this.state.activityDetail ? this.state.activityDetail : null}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: any): any => {
  if (!store) {
    return ownProps;
  }
  const newProps: any = Object.assign({}, ownProps, {
    term_id: store.state.term_id,
    examDates: store.state.examPeriodModal && store.state.examPeriodModal.exam_dates,
    solutionActivityAddSelectOptions: store.state.select_options && store.state.select_options.solutionActivityPage,
    activityDetail: store.state.activity_page && store.state.activity_page.form,
  });
  return newProps;
};

const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  return false;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(SolutionActivityAddModal);

export default container;
