import { Formik, FormikActions, FormikProps } from 'formik';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';
import { Modal } from 'reactstrap';
import MultipleCheckbox from '../../components/checkboxes/multiple-checkbox';
import Paginate from '../../components/table/paginate';
import SortedColumn from '../../components/table/sorted-column';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import Announce from '../../components/templates/announce';
import {
  InstructorSearchInitialValues,
  InstructorHoursSearchInitialValues,
  DailyMaxClasses,
  Titles,
  StaffTypes,
  TitlesLabel
} from '../../store/constants/instructor-const';
import * as Types from '../../store/types';
import * as GT from '../../tools/general-tools';
import Translator from '../../services/translate-factory';

const T = Translator.create();
const L = Log.create('InstructorAddModal');

function getInitialState(): Types.IInstructorPageState {
  const initialValues: Types.IInstructorPageState = {
    filters: Object.assign({}, InstructorHoursSearchInitialValues),
    filterIsOpen: false,
    all_ids: [],
    selected_ids: [],
    instructorFormIsOpen: false,
    instructorId: undefined
  };
  initialValues.filters.onlySelectWithoutInstructorHours = true;
  return Object.assign({}, initialValues);
}

class InstructorAddModal extends Component<Types.IInstructorPageProps, Types.IInstructorPageState> {
  state = getInitialState();

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e as string);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);

    /*let regexObject = window.location.pathname.match(/([^\/]+$)/); // gets the id of the calendar
    let id = regexObject ? parseInt(regexObject[0], 10) : undefined;*/
    var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
    let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
    this.state.filters.term_id = id;
    this.searchInstructors();
    this.getInstructorSelectOptions();
    this.getProgramsByFacultiesAtInstructors([]);
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  searchInstructors() {
    this.state.filters.status = [1];
    this.state.filters.onlySelectWithoutInstructorHours = true;
    this.props.dispatch(
      Actions.ApiRequest(Constants.instructor.INSTRUCTOR_LIST_SEARCH, this.state.filters, 'instructor-list-spin')
    );
  }

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.setState(getInitialState());
      this.props.onClose(refresh);
    }
  };

  setCloseModal = () => {
    this.setClose();
  };

  onUpdateListFromModal = (refresh: boolean = false) => {
    if (this.props.onUpdateList) {
      this.props.onUpdateList(refresh);
    }
  };

  setUpdateList = () => {
    this.onUpdateListFromModal();
  };

  getInstructorSelectOptions() {
    this.props.dispatch(Actions.ApiRequest(Constants.instructor.INSTRUCTOR_GET_SELECT_OPTIONS, 'instructor-list-spin'));
  }

  getProgramsByFacultiesAtInstructors = (facultyIds: any) => {
    this.props.dispatch(Actions.ApiRequest(Constants.instructor.INSTRUCTOR_GET_PROGRAMS_BY_FACULTIES, facultyIds, 'instructor-list-spin'));
  }

  createStaffType(type: number) {
    let staffType = StaffTypes(T).find((item) => item.value == type);
    return staffType && staffType.label;
  }

  createBadge(degree: number) {
    switch (degree) {
      case 0:
        return (
          <span className="badge badge-danger" style={{ fontSize: '95%', textAlign: 'center' }}>
            Gözetmenlik Yapmaz
          </span>
        );
      case 1:
        return (
          <span className="badge badge-warning" style={{ fontSize: '95%' }}>
            Çok Düşük
          </span>
        );
      case 2:
        return (
          <span className="badge badge-warning" style={{ fontSize: '95%' }}>
            Düşük
          </span>
        );
      case 3:
        return (
          <span className="badge badge-info" style={{ fontSize: '95%' }}>
            Orta
          </span>
        );
      case 4:
        return (
          <span className="badge badge-success" style={{ fontSize: '95%' }}>
            Yüksek
          </span>
        );
      case 5:
        return (
          <span className="badge badge-success" style={{ fontSize: '95%' }}>
            Çok Yüksek
          </span>
        );
      default:
        return (
          <span className="badge badge-dark" style={{ fontSize: '95%' }}>
            Derece Verilmedi
          </span>
        );
    }
  }

  onSelectInstructor = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      let checkedList = Object.assign([], this.state.selected_ids);
      let stringID: string = e.currentTarget.dataset.id || '';
      let id = parseInt(stringID, 10);

      if (e.target.checked) {
        checkedList.push(id);
      } else {
        let index = checkedList.indexOf(id);
        if (index !== -1) {
          checkedList.splice(index, 1);
          this.state.filters.select_all = false;
        }
        this.setState({
          ...this.state,
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
      this.setState({
        ...this.state,
        selected_ids: checkedList
      });
    }
  };

  onSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      if (e.currentTarget.checked) {
        this.state.filters.select_all = true;
        this.setState(this.state);
        this.searchInstructors();
      } else {
        this.state.all_ids = [];
        this.state.selected_ids = [];
        this.state.filters.select_all = false;
        this.setState(this.state);
      }
    }
  };

  addInstructors = (e: React.MouseEvent<HTMLSpanElement>) => {
    if (e && e.target) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_instructors_add_planning'),
          body: T.t('gen_add_instructors_to_course_schedule_question'),
          name: 'examPeriod_instructors_delete',
          icon: 'warning',
          iconColor: 'red',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_cancel'),
          onConfirm: () => {
            const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
              if (status == 200) {
                this.setClose();
                this.onUpdateListFromModal();
              }
            };
            /*let regexObject = window.location.pathname.match(/([^\/]+$)/); // gets the id of the calendar
            let id = regexObject ? parseInt(regexObject[0], 10) : undefined;*/
            var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
            let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
            this.state.filters.term_id = id;
            this.state.filters.add_list = this.state.selected_ids;

            this.props.dispatch(
              Actions.ApiRequest(
                Constants.exam_period.EXAM_PERIOD_ADD_INSTRUCTORS_TO_PERIOD,
                this.state.filters,
                'ep-instructor-list',
                resultCallback
              )
            );
          }
        })
      );
    }
  };

  sort = (sortkey: string, order_by: string) => {
    this.state.filters.order_by = sortkey + '_' + order_by;
    this.setState(this.state);
    this.searchInstructors();
  };

  onPageChange = (page: number, size?: number) => {
    this.setState(prev => ({
      ...prev,
      filters: {
        ...prev.filters,
        page: page,
        size: size ? size : 10
      }
    }), this.searchInstructors)
  };

  onSizeChange = (size: number) => {
    this.setState(prev => ({
      ...prev,
      filters: {
        ...prev.filters,
        size: size
      }
    }), this.searchInstructors)
  }

  onFormReset = () => {
    this.state.filters = Object.assign({}, InstructorSearchInitialValues);
    this.state.all_ids = [];
    this.setState(this.state);
    this.searchInstructors();
  };

  onFilterInstructor(model: Types.IFilterInstructor, FormActions: FormikActions<Types.IFilterInstructor>) {
    this.setState(prev => ({
      ...prev,
      selected_ids: [],
      filters: {
        ...model,
        page: 1,
      },
      filterIsOpen: true,
    }));
    this.searchInstructors();
    FormActions.setSubmitting(false);
  }

  static getDerivedStateFromProps(props: Types.IInstructorPageProps, state: Types.IInstructorPageState) {
    let hasNewState: boolean = false;
    if (state.filters.select_all) {
      hasNewState = true;
      state.all_ids = props.all_ids;
      state.selected_ids = props.all_ids;
    }

    if (props.modalIsOpen && !state.instructorFormIsOpen) {
      hasNewState = true;
      props.dispatch(
        Actions.ApiRequest(Constants.instructor.INSTRUCTOR_LIST_SEARCH, state.filters, 'instructor-list-spin')
      );
      state.instructorFormIsOpen = true;
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  render() {
    let instructorList = this.props.results;

    let titles = Titles(T);
    if (this.props.selectOptions && this.props.selectOptions.additional_titles && this.props.selectOptions.additional_titles.length > 0) {
      titles = [...Titles(T), ...this.props.selectOptions.additional_titles].sort((a, b) => (a.label.toLowerCase() >= b.label.toLocaleLowerCase() ? 1 : -1));
    }

    return (
      <Modal
        className="pt-0"
        style={{ maxWidth: '100%', padding: '0 15px' }}
        isOpen={this.props.modalIsOpen}
        toggle={this.setCloseModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title d-inline-flex align-items-center" id="exampleModalLabel">
              {T.t('gen_add_instructors_to_list')}
            </h6>
            <button
              id='button_close'
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.setCloseModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-12">
                  <div className="quick-actions">
                    <button
                      id='button_filter'
                      className="category-tag-square tag-glass float-right ml-3 mr-3"
                      style={{ margin: '5px' }}
                      onClick={() => {
                        this.state.filterIsOpen = !this.state.filterIsOpen;
                        this.setState(this.state);
                      }}
                    >
                      <i className="material-icons mr-2">filter_list</i>
                      <span>{T.t('gen_filter')}</span>
                    </button>
                    {this.state.selected_ids && this.state.selected_ids.length ? (
                      <React.Fragment>
                        <button
                          id='button_cancel_selection'
                          className="category-tag-square tag-gray float-left pr-2 pl-2"
                          style={{ margin: '5px' }}
                          onClick={() => {
                            this.setState({
                              ...this.state,
                              selected_ids: [],
                              all_ids: [],
                              filters: {
                                ...this.state.filters,
                                select_all: false
                              }
                            });
                          }}
                        >
                          <i className="material-icons mr-2">close</i>
                          <span>
                            {T.t('gen_cancel_selection')} <b>&nbsp;({this.state.selected_ids.length})</b>
                          </span>
                        </button>
                        <button
                          id='add_definitions_button'
                          className="category-tag-square tag-green float-left pr-2 pl-2"
                          style={{ margin: '5px' }}
                          onClick={this.addInstructors}
                        >
                          <i className="material-icons mr-2">input</i>
                          <span>{T.t('gen_add_selecteds')}</span>
                        </button>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <button
                          id='button_selecteds_add'
                          className="category-tag-square tag-gray float-left pr-2 pl-2"
                          style={{ margin: '5px', cursor: 'not-allowed' }}
                          disabled={true}
                        >
                          <i className="material-icons mr-2">input</i>
                          <span>{T.t('gen_add_selecteds')}</span>
                        </button>
                      </React.Fragment>
                    )}
                  </div>
                </div>
                {/* FILTER STARTS HERE  */}
                <div
                  className={`white-container mt-4 collapse ` + (this.state.filterIsOpen ? `show` : ``)}
                  id="advance-search"
                >
                  <div className="advance-search d-block mt-3" style={{}}>
                    <Formik
                      initialValues={InstructorSearchInitialValues}
                      enableReinitialize={true}
                      onSubmit={(values, actions) => {
                        this.onFilterInstructor(values, actions);
                      }}
                      onReset={this.onFormReset}
                    >
                      {(props: FormikProps<Types.IFilterInstructor>) => {
                        return (
                          <form onSubmit={props.handleSubmit}>
                            <div className="row">
                              <div className="col-12">
                                <h6>{T.t('gen_filter_parameters')}</h6>
                              </div>
                              <div className="col-md-3">
                                <div className="form-input form-group date-picker">
                                  <input
                                    id="instructor_code"
                                    name="instructor_code"
                                    value={props.values.instructor_code}
                                    onChange={props.handleChange}
                                    type="text"
                                    required
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="instructor_code">{T.t("gen_code")}</label>
                                  <i className="material-icons">filter_1</i>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-input form-group date-picker">
                                  <input
                                    id="name"
                                    name="name"
                                    value={props.values.name}
                                    onChange={props.handleChange}
                                    type="text"
                                    required
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="name">{T.t('gen_name')}</label>
                                  <i className="material-icons">title</i>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_title')}</label>
                                  <Select
                                    id='select_title'
                                    className="react-select"
                                    isMulti={true}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                    }
                                    closeMenuOnSelect={false}
                                    options={titles}
                                    placeholder={T.t('gen_select_title')}
                                    value={props.values.titles ? props.values.titles : null}
                                    onChange={(
                                      options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                    ) => {
                                      const list: Array<Types.ISelectOption> = options
                                        ? (options as Array<Types.ISelectOption>)
                                        : [];
                                      props.setFieldValue('titles', list);
                                      props.setFieldValue(
                                        'title_ids',
                                        list.map((item) => item.value)
                                      );
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_title')}
                                  />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-input form-group date-picker">
                                  <input
                                    id="email"
                                    name="email"
                                    value={props.values.email}
                                    onChange={props.handleChange}
                                    type="text"
                                    required
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="email">{T.t('gen_email')}</label>
                                  <i className="material-icons">title</i>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_faculty_of_duty')}</label>
                                  <Select
                                    id='select_faculty'
                                    className="react-select"
                                    isMulti={true}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                    }
                                    closeMenuOnSelect={false}
                                    options={
                                      this.props.selectOptions && this.props.selectOptions.faculties
                                        ? this.props.selectOptions.faculties
                                        : []
                                    }
                                    placeholder={T.t('gen_select_faculty')}
                                    value={props.values.faculties}
                                    onChange={(
                                      options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                    ) => {
                                      const list: Array<Types.ISelectOption> = options
                                        ? (options as Array<Types.ISelectOption>)
                                        : [];
                                      props.setFieldValue('faculties', list);
                                      props.setFieldValue(
                                        'faculty_ids',
                                        list.map((item) => item.value)
                                      );
                                      this.getProgramsByFacultiesAtInstructors(list.map(item => item.value));
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_faculty')}
                                  />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_program_of_duty')}</label>
                                    <Select
                                      id='program'
                                      className="react-select"
                                      isMulti={true}
                                      filterOption={(option: any, query: any) =>
                                        option.label && option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                      }
                                      closeMenuOnSelect={false}
                                      options={this.props.programs_related_faculty}
                                      placeholder={T.t('gen_select_program')}
                                      value={props.values.programs}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('programs', list);
                                        props.setFieldValue(
                                          'program_ids',
                                          list.map((item) => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_program')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_staff_type')}</label>
                                  <Select
                                    id='select_staff'
                                    className="react-select"
                                    isMulti={true}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                    }
                                    closeMenuOnSelect={false}
                                    options={StaffTypes(T)}
                                    placeholder={T.t('gen_select_staff_type')}
                                    value={props.values.staff_types ? props.values.staff_types : null}
                                    onChange={(
                                      options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                    ) => {
                                      const list: Array<Types.ISelectOption> = options
                                        ? (options as Array<Types.ISelectOption>)
                                        : [];
                                      props.setFieldValue('staff_types', list);
                                      props.setFieldValue(
                                        'staff_type_ids',
                                        list.map((item) => item.value)
                                      );
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_staff_type')}
                                  />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_daily_max_course')}</label>
                                  <Select
                                    id='select_daily_max_classes'
                                    className="react-select"
                                    isMulti={true}
                                    closeMenuOnSelect={false}
                                    options={DailyMaxClasses}
                                    placeholder={T.t('gen_select_daily_max_classes')}
                                    value={props.values.daily_max_classes}
                                    onChange={(
                                      options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                    ) => {
                                      const list: Array<Types.ISelectOption> = options
                                        ? (options as Array<Types.ISelectOption>)
                                        : [];
                                      props.setFieldValue('daily_max_classes', list);
                                      props.setFieldValue(
                                        'daily_max_classes_ids',
                                        list.map((item) => item.value)
                                      );
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_daily_max_classes')}
                                  />
                                </div>
                              </div>
                            </div>
                            <hr />
                            <div className="row mt-3">
                              <div className="col-6">
                                <button
                                  id='button_arrow_upward'
                                  type="button"
                                  onClick={() => {
                                    this.state.filterIsOpen = false;
                                    this.setState(this.state);
                                  }}
                                  className="mw-none mt-md-0 mt-2 mb-md-0 mb-2 btn-gray btn"
                                >
                                  <i className="material-icons">arrow_upward</i>
                                </button>
                                <button
                                  id='button_delete_sweep'
                                  type="reset"
                                  onClick={props.handleReset}
                                  className="mw-none btn btn-danger mt-md-0 mt-2 mb-md-0 mb-2"
                                >
                                  <i className="material-icons">delete_sweep</i>
                                </button>
                              </div>
                              <div className="col-6 text-right">
                                <button
                                  id='button_search'
                                  type="button"
                                  className="btn btn-blue mt-md-0 mt-2 mb-md-0 mb-2"
                                  onClick={() => props.handleSubmit()}
                                  disabled={props.isSubmitting}
                                >
                                  <i className="material-icons mr-2">search</i> {T.t('gen_search')}
                                </button>
                              </div>
                            </div>
                          </form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
                <div className="col-12">
                  <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
                    <thead>
                      <tr>
                        <th data-cell="select">
                          <div className="tick-radio position-relative">
                            <input
                              id='select_all_definitions'
                              type="checkbox"
                              className="form-radio"
                              checked={
                                this.state.selected_ids &&
                                this.props.filters &&
                                this.state.selected_ids.length == this.props.filters.total
                              }
                              onChange={this.onSelectAll}
                            />
                          </div>
                        </th>
                        <SortedColumn
                          datacell="status"
                          title={T.t('gen_status')}
                          sortkey="status"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="instructor_code"
                          title={T.t('gen_code')}
                          sortkey="instructor_code"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="staff_type"
                          title={T.t('gen_staff_type')}
                          sortkey="staff_type"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="title"
                          title={T.t('gen_title')}
                          sortkey="title"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="name"
                          className="d-none d-lg-table-cell d-xl-table-cell"
                          title={T.t('gen_name_surname')}
                          sortkey="name"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="email"
                          className="d-none d-lg-table-cell d-xl-table-cell"
                          title="Email"
                          sortkey="email"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <th scope="col" className="text-center">
                          {T.t('gen_cellphone')}
                        </th>
                        <th scope="col" className="text-center">
                          {T.t('gen_faculty_of_duty')}
                        </th>
                        <th scope="col" className="text-center">
                          {T.t('gen_program_of_duty')}
                        </th>
                        <SortedColumn
                          datacell="daily_max_class"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_daily_max_course')}
                          sortkey="daily_max_class"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <th scope="col" className="text-center">
                          {T.t('gen_description')}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {instructorList && instructorList.length ? (
                        instructorList.map((item: Types.IInstructorItem) => (
                          <tr key={'instructor-' + item.instructor_id} data-title={item.name}>
                            <td data-cell="select">
                              <div className="tick-radio position-relative">
                                <input
                                  id='select_instructor'
                                  type="checkbox"
                                  className="form-radio"
                                  checked={
                                    this.state.selected_ids &&
                                    this.state.selected_ids.indexOf(item.instructor_id ? item.instructor_id : -1) > -1
                                  }
                                  data-id={item.instructor_id}
                                  onChange={this.onSelectInstructor}
                                />
                              </div>
                            </td>
                            <td scope="row" data-label={T.t('gen_status')}>
                              <div className="tags ml-1 mr-4">
                                <button
                                  id='button_status'
                                  className={
                                    `tw-p-1 small-tag text-uppercase` + (item.status == 1 ? ` tag-green` : ` tag-red`)
                                  }
                                >
                                  {GT.GetActiveStatus(item.status)}
                                </button>
                              </div>
                            </td>
                            <td scope="row" data-label={T.t('gen_code')}>
                              {item.instructor_code}
                            </td>
                            <td data-label={T.t('gen_staff_type')}>
                              {this.createStaffType(item.staff_type ? item.staff_type : -1)}
                            </td>
                            <td data-label={T.t('gen_title')}>{item.title ? TitlesLabel(item.title, T) : '-'}</td>
                            <td data-label={T.t('gen_name')}>{item.name}</td>
                            <td data-label="Email">{item.email}</td>
                            <td data-label={T.t('gen_cellphone')}>{item.mobile}</td>
                            <td data-label={T.t('gen_faculty_of_duty')}>{item.faculty_of_duty && item.faculty_of_duty.label}</td>
                            <td data-label={T.t('gen_program_of_duty')}>{item.program_of_duty && item.program_of_duty.label}</td>
                            <td data-label={T.t('gen_daily_max_class')} className="text-center">
                              {item.daily_max_class}
                            </td>
                            <td data-label={T.t('gen_description')} className="text-center">
                              {item.description && item.description.length ?
                                <div className="table-scrollable-td">{item.description}</div> : '-'}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={16}>
                            <Announce title={T.t('gen_no_records_found')} />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div className="row-options justify-content-end">
                    <div
                      className="page-sorting d-flex align-items-center justify-content-center"
                      style={{ marginTop: '5px' }}
                    >
                      {this.props.results && this.props.results.length > 0 ? (
                        <Paginate filters={this.props.filters} onPageChange={this.onPageChange} onSizeChange={this.onSizeChange} />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (
  store: Types.IPersistedState,
  ownProps: Types.IInstructorPageProps
): Types.IInstructorPageProps => {
  if (!store) {
    return ownProps;
  }
  const newProps: Types.IInstructorPageProps = Object.assign({}, ownProps, {
    results: store.state.instructor_page && store.state.instructor_page.results,
    filters: store.state.instructor_page && store.state.instructor_page.filters,
    all_ids: store.state.instructor_page && store.state.instructor_page.all_ids,
    selectOptions: store.state.select_options && store.state.select_options.instructorPage,
    programs_related_faculty: store.state.select_options && store.state.select_options.programs_related_faculty
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.instructor_page) {
    return (
      !!equal(
        prev.state.instructor_page && prev.state.instructor_page.results,
        next.state.instructor_page && next.state.instructor_page.results
      ) &&
      !!equal(
        prev.state.instructor_page && prev.state.instructor_page.all_ids,
        next.state.instructor_page && next.state.instructor_page.all_ids
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.instructorPage,
        next.state.select_options && next.state.select_options.instructorPage
      )
    );
  } else {
    return true;
  }
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(InstructorAddModal);

export default container;
