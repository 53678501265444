import { ErrorMessage, Formik, FormikActions, FormikProps } from 'formik';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Modal } from 'reactstrap';
import Checkbox from '../../components/checkboxes/single-checkbox';
import Spinner from '../../components/templates/spinner';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import { ExamPeriodFormInitialValues, TermTypes } from '../../store/constants/exam-period-const';
import * as Types from '../../store/types';
import { FormValidation } from './validations/exam-period-form-val';

import Translator from '../../services/translate-factory';
const T = Translator.create();

const Logger = Log.create('CopyPeriodForm');

function getInitialState(): Types.ICopyFormState {
  const initialValues: Types.ICopyFormState = {
    model: Object.assign({}, ExamPeriodFormInitialValues),
    isSaveButtonDisabled: false
  };
  return Object.assign({}, initialValues);
}

class CopyPeriodForm extends Component<Types.ICopyFormProps, Types.ICopyFormState> {
  state: Types.ICopyFormState = getInitialState();

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.props.onClose(refresh);
    }
  };

  setCloseForm = () => {
    this.setClose();
  };

  onFormSave = (model: Types.ITermItem, FormActions: FormikActions<Types.ITermItem>) => {
    const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
      this.state.isSaveButtonDisabled = false;
      this.setState(this.state);
      if (result && result.code) {
        let errors: any = {};
        if (result.details) {
          const validations: Array<Types.IValidationResponse> = result.details;
          validations.forEach((m: Types.IValidationResponse) => {
            errors[m.field] = m.message[0];
          });
        }
        FormActions.setErrors(errors);
      }
      if (status === 200 || status === 201) {
        this.setClose(true);
      }
    };
    let newModel = {
      status: model.status,
      name: model.name,
      academic_term: model.academic_term,
      term_type: model.term_type,
      year: model.year,
      calendar_status: model.calendar_status,
      description: model.description,
      term_id: model.term_id,
      exam_type: model.exam_type
    }
    this.props.dispatch(Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_COPY, newModel, 'exam-period-form-spin', resultCallback));
  };

  static getDerivedStateFromProps(props: Types.ICopyFormProps, state: Types.ICopyFormState) {
    let hasNewState: boolean = false;

    if (props.formIsOpen && props.examPeriodId && props.examPeriodId != state.model.term_id) {
      state.model.term_id = props.examPeriodId;
      hasNewState = true;
    }

    if (props.form && props.examPeriodId && props.examPeriodId == state.model.term_id) {
      state.model = props.form;
      hasNewState = true;
    }

    if (hasNewState) {
      return state;
    } else if (!props.examPeriodId && state.model.term_id) {
      return getInitialState();
    } else return null;
  }

  render() {
    let yearFound = (this.props.terms!.filter((i: any) => this.props.selectedTermId && this.props.selectedTermId == i.term_id))[0];
    let currentYear = yearFound && yearFound.year;
    if (currentYear == undefined) {
      currentYear = new Date().getFullYear();
    }
    let lastYear = currentYear - 1;
    let nextYear = currentYear + 1;
    let listOfYears = [
      { label: lastYear && lastYear.toString(), value: lastYear },
      { label: currentYear && currentYear.toString(), value: currentYear },
      { label: nextYear && nextYear.toString(), value: nextYear }
    ];
    return (
      <Modal
        modalClassName="modal-from-right"

        className="pt-0"
        isOpen={this.props.formIsOpen}
      >
        <Spinner name="exam-period-form-spin" />
        <Formik
          initialValues={this.state.model}
          enableReinitialize={true}
          onSubmit={(values, actions) => {
            this.state.isSaveButtonDisabled = true;
            this.setState(this.state);
            this.onFormSave(values, actions);
          }}
          validationSchema={FormValidation(T)}
        >
          {(props: FormikProps<Types.ITermItem>) => {
            const { values, errors, handleChange, handleBlur, handleSubmit } = props;
            return (
              <form onSubmit={handleSubmit}>
                <div className="" id="addNew">
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">
                          <i className="material-icons mr-2">add_circle_outline</i>
                          <span>{T.t('gen_copy_term')}</span>
                        </h5>
                        <button id='button_close' type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.setCloseForm}>
                          <i className="material-icons">close</i>
                          <span>ESC</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_year')}</label>
                                <Select
                                  id='select_year'
                                  className="react-select"
                                  isMulti={false}
                                  closeMenuOnSelect={true}
                                  options={listOfYears}
                                  placeholder={T.t('gen_select_year')}
                                  value={listOfYears.find(option => option.value === values.year)}
                                  onChange={(option: any) => props.setFieldValue('year', option.value)}
                                />
                                <ErrorMessage component="div" className="error" name="year" />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_term')}</label>
                                <Select
                                  id='select_term'
                                  className="react-select"
                                  isMulti={false}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={true}
                                  options={TermTypes(T)}
                                  placeholder={T.t('gen_select_term')}
                                  value={
                                    values.academic_term != undefined
                                      ? TermTypes(T).find(option => option.value === values.academic_term)
                                      : null
                                  }
                                  onChange={(option: any) => props.setFieldValue('academic_term', option.value)}
                                  noOptionsMessage={(): string => T.t('gen_select_no_term')}
                                />
                              </div>
                              {errors && errors.academic_term && props.submitCount > 0 && <div className="error">{T.t('gen_cannot_leave_empty')}</div>}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_exam_type')}</label>
                                <Select
                                  id='select_exam_type'
                                  className="react-select"
                                  isMulti={false}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={true}
                                  options={
                                    this.props.selectOptions && this.props.selectOptions.exam_types
                                      ? this.props.selectOptions.exam_types
                                      : []
                                  }
                                  placeholder={T.t('gen_select_exam_type')}
                                  value={this.props.selectOptions && this.props.selectOptions.exam_types && props.values.exam_type
                                    ? this.props.selectOptions.exam_types.find(option => option.value === props.values.exam_type)
                                    : null}
                                  onChange={(option: any) => props.setFieldValue('exam_type', option.value)}
                                  noOptionsMessage={(): string => T.t('gen_select_no_exam_type')}
                                />
                              </div>
                              {errors && errors.exam_type && props.submitCount > 0 && <div className="error">{T.t('gen_cannot_leave_empty')}</div>}
                            </div>
                          </div>
                          <div className="col-md-12 form-input form-group with-icon">
                            <input
                              id="name"
                              name="name"
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="text"
                              required
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="name">{T.t('gen_name')}</label>
                            <ErrorMessage component="div" className="error" name="name" />
                          </div>
                          <div className="col-md-12 form-input form-group with-icon">
                            <textarea
                              name="description"
                              className="form-input"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              rows={2}
                              id="description"
                              value={values.description}
                              placeholder={T.t('gen_description') + ' ' + T.t('gen_max_char_200')}
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="description" />
                            <ErrorMessage component="div" className="error" name="description" />
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-md-6">
                            <div className="text-left">
                              <h6>{T.t('gen_status')}</h6>
                              <div className="tick-radio position-relative d-inline-block">
                                <Checkbox name="status" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer d-block">
                        <div className="row">
                          <div className="col-md">
                            <button
                              id='button_cancel'
                              type="button"
                              data-dismiss="modal"
                              className="btn btn-gray min-auto"
                              aria-label="Close"
                              onClick={this.setCloseForm}
                            >
                              {T.t('gen_cancel')}
                            </button>
                          </div>
                          <div className="col-md text-md-right">
                            <button
                              id='button_save'
                              type="button"
                              data-dismiss="modal"
                              aria-label="alert-success"
                              disabled={this.state.isSaveButtonDisabled}
                              onClick={() => props.handleSubmit()}
                              className="btn btn-green"
                            >
                              <i className="material-icons mr-2">save</i> {T.t('gen_save')}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.ICopyFormProps): Types.ICopyFormProps => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: Types.ICopyFormProps = Object.assign({}, ownProps, {
    request_id: store.state.exam_period_page && store.state.exam_period_page.requestId,
    request_status: store.state.exam_period_page && store.state.exam_period_page.requestStatus,
    selectedTermId: store.state.term_id,
    user: store.state.user,
    terms: store.state.term_list,
    selectOptions: store.state.select_options && store.state.select_options.termsPage,
  });
  return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  // False etmek zorundayız çünkü eski formu tutuyor. Her defasında render edilmesi lazım.
  return false;
};

const container = connect(mapStateToProps, dispatchProps, null, { areStatesEqual })(CopyPeriodForm);

export default container;
