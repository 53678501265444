import { ErrorMessage, Formik, FormikActions, FormikProps } from 'formik';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Modal } from 'reactstrap';
import Checkbox from '../../components/checkboxes/single-checkbox';
import Spinner from '../../components/templates/spinner';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import { TermTypes } from '../../store/constants/exam-period-const';
import * as Types from '../../store/types';
import * as GT from '../../tools/general-tools';
import Translator from '../../services/translate-factory';
import { EventPeriodFormInitialValues } from '../../store/constants/event-period-const';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';
import { FormValidation } from './validations/form-validation';
import { ValueType } from 'react-select/lib/types';

const T = Translator.create();

const Logger = Log.create('EventPeriodForm');

function getInitialState(): Types.IEventPeriodFormState {
    const initialValues: Types.IEventPeriodFormState = {
        model: Object.assign({}, EventPeriodFormInitialValues),
        course_term_list: [],
        exam_term_list: [],
        locale: GT.getLocaleFromLangCode()
    };
    return Object.assign({}, initialValues);
}

class EventPeriodForm extends Component<Types.IEventPeriodFormProps, Types.IEventPeriodFormState> {
    state: Types.IEventPeriodFormState = getInitialState();

    langChanged = () => {
        setTimeout(() => {
            try {
                this.forceUpdate();
            } catch (e) {
                Logger.error(e as string);
            }
        }, 1000);
    };

    componentDidMount() {
        T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
        T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
        this.getTermSelectOptions();
        document.onkeyup = (e: KeyboardEvent) => {
            if (this.props.formIsOpen && e.key === 'Escape') {
                this.setClose();
            }
        };
    }

    componentWillUnmount() {
        T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    }

    setClose = (refresh: boolean = false) => {
        if (this.props.onClose) {
            this.props.onClose(refresh);
        }
    };

    setCloseForm = () => {
        this.setClose();
    };

    getTermSelectOptions() {
        this.props.dispatch(Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_GET_SELECT_OPTIONS, 'event-period-spinner'));
    }

    onFormSave = (model: Types.ITermItem, FormActions: FormikActions<Types.ITermItem>) => {

        const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
            if (result && result.code) {
                let errors: any = {};
                if (result.details) {
                    const validations: Array<Types.IValidationResponse> = result.details;
                    validations.forEach((m: Types.IValidationResponse) => {
                        errors[m.field] = m.message[0];
                    });
                }
                FormActions.setErrors(errors);
            }
            if (status === 200 || status === 201) {
                this.props.dispatch(Actions.ApiRequest(Constants.event_period.EVENT_PERIOD_GET_LIST, null, 'term-selector-spinner'));
                this.setClose(true);
            }
        };

        model.term_type = 2;

        let connectedSolutionIds: Array<number> = [];
        if (model.courseSolutionSelectIds != undefined) {
            connectedSolutionIds = connectedSolutionIds.concat(model.courseSolutionSelectIds);
        }
        if (model.examSolutionSelectIds != undefined) {
            connectedSolutionIds = connectedSolutionIds.concat(model.examSolutionSelectIds);
        }
        if (connectedSolutionIds.length > 0) {
            model.connected_solution_ids = connectedSolutionIds;
        }

        if (this.props.eventPeriodId) {
            this.props.dispatch(Actions.ApiRequest(Constants.event_period.EVENT_PERIOD_UPDATE, model, 'event-period-form-spin', resultCallback));
        } else {
            this.props.dispatch(Actions.ApiRequest(Constants.event_period.EVENT_PERIOD_CREATE, model, 'event-period-form-spin', resultCallback));
        }
        FormActions.setSubmitting(false);
    };

    static getDerivedStateFromProps(props: Types.IEventPeriodFormProps, state: Types.IEventPeriodFormState) {
        let hasNewState: boolean = false;

        if (props.formIsOpen && props.eventPeriodId && props.eventPeriodId != state.model.term_id) {
            state.model.term_id = props.eventPeriodId;
            props.dispatch(Actions.ApiRequest(Constants.event_period.EVENT_PERIOD_GET_BY_ID, props.eventPeriodId, 'event-period-form-spin'));
            hasNewState = true;
        }
        if (props.terms) {
            const createTermOption = (term: any) => ({
                label: `${term.year} ${GT.GetAcademicTermText(term.academic_terms)} ${GT.GetTermTypeText(term.term_type)} / ${term.name} (${term.term_id})`,
                value: term.term_id
            });

            props.terms.forEach((term) => {
                if (term.term_type === 0) {
                    state.course_term_list.push(createTermOption(term));
                } else if (term.term_type === 1) {
                    state.exam_term_list.push(createTermOption(term));
                }
            });
        }

        if (props.form && props.eventPeriodId && props.eventPeriodId == state.model.term_id) {
            state.model = props.form;
            hasNewState = true;
        }

        if (hasNewState) {
            return state;
        } else if (!props.eventPeriodId && state.model.term_id) {
            return getInitialState();
        } else return null;
    }
    getTermYear() {
        return this.props && this.props.terms == undefined ? new Date().getFullYear()
            : this.props.terms!.filter((i: any) => this.props.selectedTermId && this.props.selectedTermId == i.term_id)[0];
    }
    getTitle() {
        return this.props.eventPeriodId ? <><i className="material-icons mr-2">edit</i>{T.t('gen_editing')}</> : <><i className="material-icons mr-2">add_circle_outline</i> {T.t('gen_add_event_term')}</>
    }
    render() {

        let termYear = this.getTermYear()
        let currentYear = termYear && termYear.year || new Date().getFullYear()
        let lastYear = currentYear - 1
        let nextYear = currentYear + 1
        let listOfYears = [
            { label: lastYear && lastYear.toString(), value: lastYear },
            { label: currentYear && currentYear.toString(), value: currentYear },
            { label: nextYear && nextYear.toString(), value: nextYear }
        ];

        return (
            <Modal
                modalClassName="modal-from-right"

                className="pt-0"
                isOpen={this.props.formIsOpen}
            >
                <Spinner name="event-period-form-spin" />
                <Formik
                    initialValues={this.state.model}
                    enableReinitialize={true}
                    onSubmit={(values, actions) => {
                        this.onFormSave(values, actions);
                    }}
                    validationSchema={FormValidation(T)}
                >
                    {(props: FormikProps<Types.ITermItem>) => {
                        const { values, handleChange, handleBlur, handleSubmit, setFieldValue } = props;
                        if (this.props.selectOptions && this.props.selectOptions.course_solutions && props.values.courseSolutionSelect == undefined) {
                            let options = this.props.selectOptions.course_solutions.filter((x) => values.connected_solution_ids != undefined && values.connected_solution_ids.includes(x.value))
                            if (options != undefined && options.length > 0) {
                                props.setFieldValue('courseSolutionSelect', options);
                                props.setFieldValue(
                                    'courseSolutionSelectIds',
                                    options.map((item) => item.value)
                                );
                            }
                        }
                        if (this.props.selectOptions && this.props.selectOptions.exam_solutions && props.values.examSolutionSelect == undefined) {
                            let options = this.props.selectOptions.exam_solutions.filter((x) => values.connected_solution_ids != undefined && values.connected_solution_ids.includes(x.value))
                            if (options != undefined && options.length > 0) {
                                props.setFieldValue('examSolutionSelect', options);
                                props.setFieldValue(
                                    'examSolutionSelectIds',
                                    options.map((item) => item.value)
                                );
                            }
                        }
                        return (
                            <form onSubmit={handleSubmit}>
                                <div className="" id="addNew">
                                    <div className="modal-dialog" aria-role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title">
                                                    {this.getTitle()}
                                                </h5>
                                                <button id='button_close' type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.setCloseForm}>
                                                    <i className="material-icons">close</i>
                                                    <span>ESC</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="add-custom-tag mb-3">
                                                            <div className="react-select-container">
                                                                <label>{T.t('gen_year')}</label>
                                                                <Select
                                                                    id='select_year'
                                                                    className="react-select"
                                                                    isMulti={false}
                                                                    closeMenuOnSelect={true}
                                                                    options={listOfYears}
                                                                    placeholder={T.t('gen_select_year')}
                                                                    value={listOfYears.find(option => option.value === values.year)}
                                                                    onChange={(option: any) => props.setFieldValue('year', option.value)}
                                                                />
                                                                <ErrorMessage component="div" className="error" name="year" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="add-custom-tag">
                                                            <div className="react-select-container">
                                                                <label>{T.t('gen_term')}</label>
                                                                <Select
                                                                    id='select_term'
                                                                    className="react-select"
                                                                    isMulti={true}  // Multi-select özelliği aktif edildi
                                                                    filterOption={(option: any, query: any) =>
                                                                        option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                                                    }
                                                                    closeMenuOnSelect={false}  // Multi-select'te menü kapatılmaz
                                                                    options={TermTypes(T)}
                                                                    placeholder={T.t('gen_select_term')}
                                                                    value={
                                                                        values.academic_terms && values.academic_terms.length > 0
                                                                            ? TermTypes(T).filter(option => values.academic_terms && values.academic_terms.includes(option.value))
                                                                            : []
                                                                    }
                                                                    onChange={(selectedOptions: any) => {
                                                                        props.setFieldValue('academic_terms', selectedOptions ? selectedOptions.map((option: any) => option.value) : []);
                                                                    }}
                                                                    noOptionsMessage={(): string => T.t('gen_select_no_term')}
                                                                />
                                                                <ErrorMessage component="div" className="error" name="academic_terms" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="react-select-container">
                                                            <label>{T.t('gen_course_solution')}</label>
                                                            <Select
                                                                className="react-select"
                                                                placeholder={T.t('gen_select_solution')}
                                                                noOptionsMessage={(): string => T.t('gen_select_no_solution')}
                                                                filterOption={(option: any, query: any) =>
                                                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                                                }
                                                                options={this.props.selectOptions && this.props.selectOptions.course_solutions}
                                                                isMulti={true}
                                                                closeMenuOnSelect={false}
                                                                value={props.values.courseSolutionSelect ? props.values.courseSolutionSelect : null}
                                                                onChange={(options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>) => {
                                                                    const list: Array<Types.ISelectOption> = options
                                                                        ? (options as Array<Types.ISelectOption>)
                                                                        : [];
                                                                    props.setFieldValue('courseSolutionSelect', list);
                                                                    props.setFieldValue(
                                                                        'courseSolutionSelectIds',
                                                                        list.map((item) => item.value)
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="react-select-container">
                                                            <label>{T.t('gen_exam_solution')}</label>
                                                            <Select
                                                                className="react-select"
                                                                placeholder={T.t('gen_select_solution')}
                                                                noOptionsMessage={(): string => T.t('gen_select_no_solution')}
                                                                filterOption={(option: any, query: any) =>
                                                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                                                }
                                                                options={this.props.selectOptions && this.props.selectOptions.exam_solutions}
                                                                isMulti={true}
                                                                closeMenuOnSelect={false}
                                                                value={props.values.examSolutionSelect ? props.values.examSolutionSelect : null}
                                                                onChange={(options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>) => {
                                                                    const list: Array<Types.ISelectOption> = options
                                                                        ? (options as Array<Types.ISelectOption>)
                                                                        : [];
                                                                    props.setFieldValue('examSolutionSelect', list);
                                                                    props.setFieldValue(
                                                                        'examSolutionSelectIds',
                                                                        list.map((item) => item.value)
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 form-input form-group with-icon">
                                                        <input
                                                            id="name"
                                                            name="name"
                                                            value={values.name}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            type="text"
                                                            required
                                                        />
                                                        <span className="highlight" />
                                                        <span className="bar" />
                                                        <label htmlFor="name">{T.t('gen_name')}</label>
                                                        <ErrorMessage component="div" className="error" name="name" />
                                                    </div>
                                                    <div className="col-md-12 form-input form-group with-icon">
                                                        <textarea
                                                            name="description"
                                                            className="form-input"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            rows={2}
                                                            id="description"
                                                            value={values.description}
                                                            placeholder={T.t('gen_description') + ' ' + T.t('gen_max_char_200')}
                                                        />
                                                        <span className="highlight" />
                                                        <span className="bar" />
                                                        <label htmlFor="description" />
                                                        <ErrorMessage component="div" className="error" name="description" />
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className="col-md-12 form-input form-group with-icon">
                                                        <Flatpickr
                                                            placeholder={T.t('gen_select_dates')}
                                                            value={values.time_range ? [moment(values.time_range.start).toDate(), moment(values.time_range.end).toDate()] : []}
                                                            options={{
                                                                dateFormat: 'd.m.Y',
                                                                mode: 'range',
                                                                locale: this.state.locale,
                                                                defaultDate: values.time_range ? [moment(values.time_range.start).toDate(), moment(values.time_range.end).toDate()] : []
                                                            }}
                                                            onChange={(selectedDates) => {
                                                                if (selectedDates.length === 2) {
                                                                    const startDate = moment(selectedDates[0]).format('YYYY.MM.DD');
                                                                    const endDate = moment(selectedDates[1]).format('YYYY.MM.DD');

                                                                    setFieldValue('time_range', { start: startDate, end: endDate });
                                                                }
                                                            }}
                                                        />
                                                        <span className="highlight" />
                                                        <span className="bar" />
                                                        <label htmlFor="time_range">{T.t('gen_start_and_end_dates')}</label>
                                                        <ErrorMessage component="div" className="error" name="time_range" />
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-md-6">
                                                        <div className="text-left">
                                                            <h6>{T.t('gen_status')}</h6>
                                                            <div className="tick-radio position-relative d-inline-block">
                                                                <Checkbox name="status" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer d-block">
                                                <div className="row">
                                                    <div className="col-md">
                                                        <button
                                                            id='button_cancel'
                                                            type="button"
                                                            data-dismiss="modal"
                                                            className="tertiary min-auto"
                                                            aria-label="Close"
                                                            onClick={this.setCloseForm}
                                                        >
                                                            {T.t('gen_cancel')}
                                                        </button>
                                                    </div>
                                                    <div className="col-md text-md-right">
                                                        <button
                                                            id='button_save'
                                                            type="button"
                                                            data-dismiss="modal"
                                                            aria-label="alert-success"
                                                            onClick={() => props.handleSubmit()}
                                                            className="primary"
                                                        >
                                                            <i className="material-icons mr-2">save</i> {T.t('gen_save')}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        );
                    }}
                </Formik>
            </Modal>
        );
    }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IEventPeriodFormProps): Types.IEventPeriodFormProps => {
    if (!store || !store.state) {
        return ownProps;
    }
    const newProps: Types.IEventPeriodFormProps = Object.assign({}, ownProps, {
        form: store.state.event_period_page && store.state.event_period_page.form ? store.state.event_period_page.form : getInitialState(),
        selectedTermId: store.state.term_id,
        user: store.state.user,
        terms: store.state.term_list,
        selectOptions: store.state.select_options && store.state.select_options.termsPage,
    });
    return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
    if (next.state.event_period_page) {
        return !!equal(
            prev.state.event_period_page && prev.state.event_period_page.form,
            next.state.event_period_page && next.state.event_period_page.form
        );
    } else {
        return true;
    }
};

const container = connect(mapStateToProps, dispatchProps, null, { areStatesEqual })(EventPeriodForm);

export default container;
