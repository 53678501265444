import { Formik, FormikActions, FormikProps } from 'formik';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import Paginate from '../../components/table/paginate';
import SortedColumn from '../../components/table/sorted-column';
import Announce from '../../components/templates/announce';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import { DistributorModalSearchInitialValues, DistributorJobStatus, ErrorCodeExam, DistributorActions } from '../../store/constants/distributor-const';
import * as Types from '../../store/types';
import Translator from '../../services/translate-factory';
import ChangeSolutionModal from './course-timeslot-edit-modal';
import ChangeInvigilatorModal from './course-invigilator-edit-modal';
import ChangeClassroomModal from './course-classroom-edit-modal';
import ChangeSingleExamModal from './single-exam-edit-modal';
import ChangeSingleCourseModal from './single-course-edit-modal';
import { EventSourcePolyfill } from 'event-source-polyfill';
import Timer from '../../components/datetime/timer-memo';
import * as GT from '../../tools/general-tools';
import { routes as Routes } from '../../store/constants/routes';
import { ExamTermTabs } from '../../store/constants/exam-period-const';
import { SectionTypes } from '../../store/constants/enums';
import APlanLogo from '../../assets_aplan/images/plan-365-logo.png';
import coLogo_short from '../../assets/logos/co_short.png';

declare const appSettings: Types.IAppSettings;

const T = Translator.create();
const L = Log.create('DistributorModal');

class DistributorModal extends Component<any, any> {
  state: Types.IDistributorModalState = {
    filters: DistributorModalSearchInitialValues,
    statusTableIsOpen: true,
    filterIsOpen: false,
    statusHistoryList: [],
    changeSolutionModalIsOpen: false,
    reassignInvigilatorsManualModalIsOpen: false,
    changeClassroomModalIsOpen: false,
    changeSingleExamModalIsOpen: false,
    changeSingleCourseModalIsOpen: false,
    solutionResultIsOpen: false,
    is_error_ignorable: false,
    page: 1,
    total: -1,
    size: 10,
    best_success_rate: 0,
    mean_success_rate: 0,
    best_success_rate_classroom: 0,
    solution_count: 0,
    max_number_of_attempts: 0
  };

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e as string);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
    let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
    this.state.filters.term_id = id;

    this.CreateSSE();
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    this.CloseSSE();
  }

  private CreateSSE() {
    let job_id = !!this.props.jobId ? this.props.jobId : this.props.distribution_in_progress_status.job_id;

    const apiUrl = new URL(appSettings.API_URL).origin;
    const path = apiUrl === "http://localhost:3000" ? "http://localhost:2900" : apiUrl + "/api";

    const eventSourceLocal = new EventSourcePolyfill(path + "/distribution/" + job_id, {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("access_token"),
        'Content-Type': 'text/event-stream',
        'Cache-Control': 'no-cache',
        'page': '' + this.state.page,
        'TermId': this.props.term_id.toString(),
        'Language': T.getSelectedLanguage()
      }
    }) as EventSource;

    this.setState({
      ...this.state,
      eventSource: eventSourceLocal
    });

    eventSourceLocal.addEventListener('inventory', (e: any) => {
      if (e.data) {
        const jobInventory: Types.ISSEInventory = JSON.parse(e.data);
        if (jobInventory) {
          let DistributionStopStatus: Array<number> = [
            DistributorJobStatus.Stopped,
            DistributorJobStatus.UnexpectedError,
            DistributorJobStatus.DataPreparation_Error,
            DistributorJobStatus.Calculations_Error,
            DistributorJobStatus.Completed_Successfully
          ];

          if (DistributionStopStatus.includes(jobInventory.status) || (jobInventory.status == DistributorJobStatus.ImpossibilityTest_Error && jobInventory.is_error_ignorable == false)) {
            this.CloseSSE();
          }

          this.setJobInventory(jobInventory);
        }
      }
    }, false);
  }

  setJobInventory(jobInventory: Types.ISSEInventory) {
    return this.setState({
      ...this.state,
      statusHistoryList: jobInventory.status_history_list && jobInventory.status_history_list.length == 0 ? [] : jobInventory.status_history_list,
      statusHistory: jobInventory.status_history == null ? this.state.statusHistory : jobInventory.status_history,
      status: jobInventory.status,
      is_error_ignorable: jobInventory.is_error_ignorable,
      total: jobInventory.total_errors,
      page: jobInventory.page,
      best_success_rate: jobInventory.best_success_rate,
      mean_success_rate: jobInventory.mean_success_rate,
      best_success_rate_classroom: jobInventory.best_success_rate_classroom,
      max_number_of_attempts: jobInventory.max_number_of_attempts,
      solution_count: jobInventory.solution_count,
      created_date: jobInventory.created_date.split('.')[2].split(' ')[0] + '-' + jobInventory.created_date.split('.')[0] + '-' + jobInventory.created_date.split('.')[1] + ' ' + jobInventory.created_date.split(' ')[1],
      end_date: jobInventory.end_date ? jobInventory.end_date.split('.')[2].split(' ')[0] + '-' + jobInventory.end_date.split('.')[0] + '-' + jobInventory.end_date.split('.')[1] + ' ' + jobInventory.end_date.split(' ')[1] : undefined,
    })
  }

  private CloseSSE() {
    const eventSourceLocal = (this.state.eventSource as EventSource);
    if (!!this.state.eventSource) {
      eventSourceLocal.close();
    }
  }

  getDistributions() {
    this.props.dispatch(
      Actions.ApiRequest(Constants.disributor.DISTRIBUTOR_GET_DISTRIBUTION_LIST, this.state.filters, 'ep-student-list')
    );
  }

  getDistributionInProgressStatus() {
    if (!!this.props.term_id && this.props.term_id != -1) {
      this.props.dispatch(
        Actions.ApiRequest(Constants.disributor.GET_DISTRIBUTION_IN_PROGRESS_STATUS, this.props.term_id, 'ep-student-list')
      );
    }
  }

  onStopDistribution = (action: any, item: any, termType: number) => {
    let DistributionStopStatus: Array<number> = [];

    if (termType == 1) {
      DistributionStopStatus = [
        DistributorJobStatus.Stopped,
        DistributorJobStatus.UnexpectedError,
        DistributorJobStatus.DataPreparation_Error,
        DistributorJobStatus.ImpossibilityTest_Ready,
        DistributorJobStatus.ImpossibilityTest_Error,
        DistributorJobStatus.Calculations_Error,
        DistributorJobStatus.ClassroomAssignment_Error,
        DistributorJobStatus.InvigilatorAssignment_Error,
        DistributorJobStatus.InvigilatorAssignment_Ready,
        DistributorJobStatus.SubmitSolution_Error,
        DistributorJobStatus.Completed_Successfully,
      ];
    } else if (termType == 0) {
      DistributionStopStatus = [
        DistributorJobStatus.Stopped,
        DistributorJobStatus.UnexpectedError,
        DistributorJobStatus.DataPreparation_Error,
        DistributorJobStatus.ImpossibilityTest_Ready,
        DistributorJobStatus.ImpossibilityTest_Error,
        DistributorJobStatus.Calculations_Error,
        DistributorJobStatus.ClassroomAssignment_Error,
        DistributorJobStatus.ClassroomAssignment_Ready,
        DistributorJobStatus.SubmitSolution_Error,
        DistributorJobStatus.Completed_Successfully,
      ];
    }

    if (this.state && this.state.status && !DistributionStopStatus.includes(this.state.status)) {
      this.props.dispatch(
        Actions.ShowModal({
          title: action == DistributorActions.Stop ? T.t('gen_stop_action') : T.t('gen_redirect_action'),
          content: (
            <div>
              <p> {this.state.status == DistributorJobStatus.SubmitSolution_Pending ? T.t('gen_distribution_cancel_submit_solution_info') : T.t('gen_distribution_stop_question')} </p>
            </div>
          ),
          name: 'distribution_stop',
          icon: 'warning',
          iconColor: 'red',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_cancel'),
          onConfirm: () => {
            const resultCallback = (result: any, status: number) => {
              if (status === 200) {
                this.getDistributionInProgressStatus();
                this.setCloseModal();
              }
            };
            let job_id = !!this.props.jobId ? this.props.jobId : this.props.distribution_in_progress_status.job_id;
            this.props.dispatch(
              Actions.ApiRequest(Constants.disributor.DISTRIBUTOR_CANCEL_DISTRIBUTION, job_id, 'distributor-cancel-spin', resultCallback)
            );

            if (item != null)
              this.onErrorRedirection(item);
          }
        })
      );
    } else {
      this.getDistributionInProgressStatus();
      this.setCloseModal();
    }
  };

  onErrorRedirection = (item: any) => {
    this.CloseSSE();
    switch (item.error_id) {
      case ErrorCodeExam.Classrooms_No_Available_Time_Slots:
        this.props.dispatch(Actions.Navigation(
          GT.Route(
            this.props.term_type == 0 ? Routes.COURSE_PERIODS : Routes.EXAM_PERIODS,
            '/calendar/' + ExamTermTabs.Classrooms + '/' + this.props.term_id
          )
        ));
        break;
      case ErrorCodeExam.Classrooms_Not_Selected:
        this.props.dispatch(Actions.Navigation(
          GT.Route(
            this.props.term_type == 0 ? Routes.COURSE_PERIODS : Routes.EXAM_PERIODS,
            '/calendar/' + ExamTermTabs.Classrooms + '/' + this.props.term_id
          )
        ));
        break;
      case ErrorCodeExam.Course_Instructors_Not_in_List_of_Instructors ||
        ErrorCodeExam.Inadequacy_of_Available_Hours_for_Instructor ||
        ErrorCodeExam.Instructors_No_Available_Time_Slots:
        this.props.dispatch(Actions.Navigation(
          GT.Route(
            this.props.term_type == 0 ? Routes.COURSE_PERIODS : Routes.EXAM_PERIODS,
            '/calendar/' + ExamTermTabs.Instructors + '/' + this.props.term_id
          )
        ));
        break;
      case ErrorCodeExam.Courses_With_Missing_Data ||
        ErrorCodeExam.Numbers_of_Programs_and_Grades_Mismatch ||
        ErrorCodeExam.Numbers_of_Programs_and_Instructors_Mismatch ||
        ErrorCodeExam.Numbers_of_Programs_and_Course_Codes_Mismatch ||
        ErrorCodeExam.Course_Not_Selected ||
        ErrorCodeExam.Course_in_Course_List_Not_in_Student_List:
        this.props.dispatch(Actions.Navigation(
          GT.Route(
            this.props.term_type == 0 ? Routes.COURSE_PERIODS : Routes.EXAM_PERIODS,
            '/calendar/' + ExamTermTabs.Courses + '/' + this.props.term_id
          )
        ));
        break;
      case ErrorCodeExam.Inadequacy_of_Consecutive_Time_Slots_for_Exam ||
        ErrorCodeExam.Inadequacy_of_Classroom_Capacities ||
        ErrorCodeExam.Inadequacy_of_Time_Slots_for_Courses_With_Common_Classroom ||
        ErrorCodeExam.No_Active_Time_Slots ||
        ErrorCodeExam.Inadequacy_of_Available_Hours_for_Program ||
        ErrorCodeExam.Student_Numbers_of_Exam_List_and_Student_List_Mismatch ||
        ErrorCodeExam.Risk_of_Inadequacy_of_Available_Hours_for_Program ||
        ErrorCodeExam.Triple_Inadequacy_of_Available_Hours:
        this.props.dispatch(Actions.Navigation(
          GT.Route(
            this.props.term_type == 0 ? Routes.COURSE_PERIODS : Routes.EXAM_PERIODS,
            '/calendar/' + ExamTermTabs.ExamDatesAndClassrooms + '/' + this.props.term_id
          )
        ));
        break;
      case ErrorCodeExam.Inadequacy_of_Available_Hours_for_Student ||
        ErrorCodeExam.Course_in_Student_List_Not_in_Course_List:
        this.props.dispatch(Actions.Navigation(
          GT.Route(
            this.props.term_type == 0 ? Routes.COURSE_PERIODS : Routes.EXAM_PERIODS,
            '/calendar/' + ExamTermTabs.Students + '/' + this.props.term_id
          )
        ));
        break;
      case ErrorCodeExam.Inadequacy_of_Campus_Durations_and_Campuses ||
        ErrorCodeExam.Campus_Codes_in_Exam_List_Not_in_Campus_List ||
        ErrorCodeExam.Campus_Durations_Error ||
        ErrorCodeExam.Faculty_Codes_in_Exam_List_Not_in_List_of_Faculties:
        this.props.dispatch(Actions.Navigation(
          GT.Route(Routes.CAMPUS)
        ));
        break;
      case ErrorCodeExam.Faculty_Codes_in_Exam_List_Not_in_List_of_Faculties:
        this.props.dispatch(Actions.Navigation(
          GT.Route(Routes.FACULTY)
        ));
        break;
      case ErrorCodeExam.Invigilation_Location_of_Instructor_Not_Selected:
        this.props.dispatch(Actions.Navigation(
          GT.Route(Routes.INSTRUCTOR)
        ));
        break;

      default:
        break;
    }
    setTimeout(() => {
      window.location.reload()
    }, 700);
  };

  onSolutionChange = () => {
    this.switchChangeSolutionModalStatus();
  }

  switchChangeSolutionModalStatus = () => {
    this.setState({
      ...this.state,
      changeSolutionModalIsOpen: !this.state.changeSolutionModalIsOpen
    });
  };

  onReassignInvigilatorsManual = () => {
    this.switchReassignInvigilatorsManualModalStatus();
  }

  switchReassignInvigilatorsManualModalStatus = () => {
    this.setState({
      ...this.state,
      reassignInvigilatorsManualModalIsOpen: !this.state.reassignInvigilatorsManualModalIsOpen
    });
  };

  onClassroomChange = () => {
    this.switchChangeClassroomModalStatus();
  }

  switchChangeClassroomModalStatus = () => {
    this.setState({
      ...this.state,
      changeClassroomModalIsOpen: !this.state.changeClassroomModalIsOpen
    });
  };

  onSingleExamChange = () => {
    this.switchChangeSingleExamModalStatus();
  }

  switchChangeSingleExamModalStatus = () => {
    this.setState({
      ...this.state,
      changeSingleExamModalIsOpen: !this.state.changeSingleExamModalIsOpen
    });
  };

  onSingleCourseChange = () => {
    this.switchChangeSingleCourseModalStatus();
  }

  switchChangeSingleCourseModalStatus = () => {
    this.setState({
      ...this.state,
      changeSingleCourseModalIsOpen: !this.state.changeSingleCourseModalIsOpen
    });
  };

  onSolutionResults = () => {
    // if () {
    //   this.props.dispatch(
    //     Actions.ShowModal({
    //       title: T.t('gen_edit'),
    //       body: T.t("gen_solution_process_info"),
    //       name: 'solution_edit',
    //       icon: 'error_outline',
    //       iconColor: 'red',
    //       cancel: T.t('gen_close')
    //     })
    //   );
    // } else {
    this.switchSolutionResultsModalStatus();
    // }
  }

  switchSolutionResultsModalStatus = () => {
    this.setState({
      ...this.state,
      solutionResultIsOpen: !this.state.solutionResultIsOpen
    });
  };

  showInfoModalStatusHistory = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e && e.target) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_download_all_info'),
          body: T.t('gen_download_all_question'),
          name: 'download_status_history',
          icon: 'info',
          iconColor: 'blue',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_cancel'),
          onConfirm: () => {
            const model = {
              termId: this.props.term_id,
              sectionType: SectionTypes.Distribution,
              sectionId: !!this.props.jobId ? this.props.jobId : this.props.distribution_in_progress_status.job_id,
              parameters: [],
              autoDownload: true
            };
            this.props.dispatch(
              Actions.ApiRequest(Constants.disributor.DISTRIBUTOR_DOWNLOAD_HISTORY, model, '',
                (result: string, status: number) => {
                  if (status === 200) {
                    this.openLinkInNewTab(result);
                  }
                })
            )
          }
        })
      );
    }
  };

  openLinkInNewTab(url: string) {
    var win = window.open(url, '_blank');
    win && win.focus();
  }

  onFilterDistributionList(model: Types.IFilterDistributorModal, FormActions: FormikActions<Types.IFilterDistributorModal>) {
    this.state.filters = model;
    this.state.filters.page = 1;
    this.state.filterIsOpen = true;
    this.setState(this.state);
    this.getDistributions();
    FormActions.setSubmitting(false);
  }

  setClose = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  setCloseModal = () => {
    this.setClose();
  };

  sort = (sortkey: string, order_by: string) => {
    this.state.filters.order_by = sortkey + '_' + order_by;
    this.setState(this.state);
    this.getDistributions();
  };

  onPageChange = (page: number) => {
    this.state.page = page;
    this.setState({
      ...this.state,
      page: page
    });

    this.CloseSSE();
    this.CreateSSE();
  };

  onFormReset = () => {
    this.state.filters = Object.assign({}, DistributorModalSearchInitialValues);
    this.setState(this.state);
    this.getDistributions();
  };

  static getDerivedStateFromProps(props: Types.IDistributorPageProps, state: Types.IDistributorPageState) {
    let hasNewState: boolean = false;

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  showInfoConfirmOptionalErrors = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e && e.target) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_distribution_confirm_optional_errors_title'),
          body: T.t('gen_distribution_confirm_optional_errors_body'),
          name: 'distribution_confirm_optional_errors',
          icon: 'info',
          iconColor: 'blue',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_cancel'),
          onConfirm: () => {
            let job_id = !!this.props.jobId ? this.props.jobId : this.props.distribution_in_progress_status.job_id;
            this.props.dispatch(Actions.ApiRequest(Constants.disributor.DISTRIBUTOR_CONFIRM_DISTRIBUTION, job_id, '',
              (result: string, status: number) => {
                if (status === 200) {
                  this.state.statusHistoryList = [];
                  window.location.reload();
                }
              })
            )
          }
        })
      );
    }
  };

  showInfoStopCalculation = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e && e.target) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_distribution_stop_calculation_title'),
          body: T.t('gen_distribution_stop_calculation_body'),
          name: 'distribution_stop_calculation',
          icon: 'info',
          iconColor: 'blue',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_cancel'),
          onConfirm: () => {
            let job_id = !!this.props.jobId ? this.props.jobId : this.props.distribution_in_progress_status.job_id;
            this.props.dispatch(Actions.ApiRequest(Constants.disributor.DISTRIBUTOR_STOP_CALCULATION, job_id, ''));
          }
        })
      );
    }
  };

  handleChangeResetMaxNumberOfAttempts(e: React.ChangeEvent<HTMLInputElement>, onResetMaxNumberOfAttempts: boolean) {
    if (onResetMaxNumberOfAttempts) {
      this.state.max_number_attempts_updated = Number(e.target.value);
      this.setState(this.state);
    }
  }

  onResetMaxNumberOfAttempts = (e: React.MouseEvent<HTMLSpanElement>) => {
    const min = Number(this.state.max_number_of_attempts) + 1;
    if (e && e.target) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_reset_max_number_of_attempts'),
          body:
            <React.Fragment>
              <Formik
                initialValues={DistributorModalSearchInitialValues}
                enableReinitialize={true}
                onSubmit={(values, actions) => {
                }}
                onReset={this.onFormReset}
              >
                {(props: FormikProps<Types.IDistributorPageProps>) => {
                  const { values, handleChange, handleBlur, handleSubmit } = props;
                  if (props.values.max_number_of_attempts_updated == undefined) {
                    props.setFieldValue('max_number_of_attempts_updated', min);
                    this.state.max_number_attempts_updated = min;
                    this.setState(this.state);
                  }
                  return (
                    <div className="form-input form-group date-picker">
                      <input
                        id="max_number_of_attempts_updated"
                        name="max_number_of_attempts_updated"
                        type="number"
                        value={props.values.max_number_of_attempts_updated}
                        onChange={handleChange}
                        //onChange={(e) => { props.setFieldValue('max_number_of_attempts_updated', Number(e.target.value) < min ? (min) : Number(e.target.value)) }}
                        onBlur={(e) => { this.handleChangeResetMaxNumberOfAttempts(e, true); }}
                      />
                      <span className="highlight" />
                      <span className="bar" />
                      <label htmlFor="max_number_of_attempts_updated">{T.t('gen_max_number_of_attempts')}</label>
                    </div>
                  )
                }}
              </Formik>
            </React.Fragment>,
          name: 'submit_solution',
          icon: 'auto_mode',
          iconColor: 'green',
          confirm: T.t('gen_validate'),
          cancel: T.t('gen_proceed_without_changing'),
          cancelCss: 'tag-red text-white',
          noCloseButton: true,
          onConfirm: () => {
            const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
              if (status == 200) {
                this.state.statusHistoryList = [];
                window.location.reload();
              } else if (status == 406) {
                this.state.max_number_attempts_updated = min;
                this.setState(this.state);
                this.props.dispatch(
                  Actions.ShowModal({
                    title: T.t("gen_error"),
                    body: T.t("gen_max_number_of_attempts_should_be_greater_than_n").replace("{0}", result),
                    name: 'reset_max_number_of_attempts_failed',
                    icon: 'error_outline',
                    iconColor: 'red'
                  }));
              }
            };
            const model = {
              job_id: !!this.props.jobId ? this.props.jobId : this.props.distribution_in_progress_status.job_id,
              max_number_attempts_updated: this.state.max_number_attempts_updated,
            };
            if (this.state.max_number_attempts_updated != undefined) {
              this.props.dispatch(Actions.ApiRequest(Constants.disributor.DISTRIBUTOR_RESET_MAX_NUMBER_OF_ATTEMPTS, model, '', resultCallback));
            } else {
              this.state.max_number_attempts_updated = min;
              this.setState(this.state);
              this.props.dispatch(
                Actions.ShowModal({
                  title: T.t("gen_error"),
                  body: T.t("gen_max_number_of_attempts_left_blank"),
                  name: 'reset_max_number_of_attempts_failed',
                  icon: 'error_outline',
                  iconColor: 'red'
                }));
            }
          },
          onCancel: () => {
            this.props.dispatch(
              Actions.ShowModal({
                title: T.t("gen_reset_max_number_of_attempts_proceed_without_changing_title"),
                body: T.t("gen_reset_max_number_of_attempts_proceed_without_changing_body"),
                name: 'reset_max_number_of_attempts_proceed_without_changing',
                icon: 'info',
                iconColor: 'blue',
                confirm: T.t('gen_validate'),
                cancel: T.t('gen_cancel'),
                noCloseButton: true,
                onConfirm: () => {
                  const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
                    if (status == 200) {
                      this.state.statusHistoryList = [];
                      window.location.reload();
                    }
                  };
                  const model = {
                    job_id: !!this.props.jobId ? this.props.jobId : this.props.distribution_in_progress_status.job_id,
                    max_number_attempts_updated: -1,
                  };
                  this.props.dispatch(Actions.ApiRequest(Constants.disributor.DISTRIBUTOR_RESET_MAX_NUMBER_OF_ATTEMPTS, model, '', resultCallback));
                },
                onCancel: () => {
                  this.state.max_number_attempts_updated = min;
                  this.setState(this.state);
                }
              }));
          }
        })
      );
    }
  };

  showInfoReassignInvigilatorsAuto = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e && e.target) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_distribution_reassign_invigilators_auto_title'),
          body: T.t('gen_distribution_reassign_invigilators_auto_body'),
          name: 'distribution_reassign_invigilators_auto',
          icon: 'info',
          iconColor: 'blue',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_cancel'),
          onConfirm: () => {
            let job_id = !!this.props.jobId ? this.props.jobId : this.props.distribution_in_progress_status.job_id;
            this.props.dispatch(Actions.ApiRequest(Constants.disributor.DISTRIBUTOR_REASSIGN_INVIGILATORS_AUTO, job_id, ''));
          }
        })
      );
    }
  };

  handleChangeSubmitSolution(e: React.ChangeEvent<HTMLInputElement>, onSubmitSolution: boolean) {
    if (onSubmitSolution) {
      this.state.explanation = e.target.value;
      this.setState(this.state);
    }
  }

  onSubmitSolution = (e: React.MouseEvent<HTMLSpanElement>) => {
    if (e && e.target) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_submit_solution'),
          body:
            <React.Fragment>
              <Formik
                initialValues={DistributorModalSearchInitialValues}
                enableReinitialize={true}
                onSubmit={(values, actions) => {
                }}
                onReset={this.onFormReset}
              >
                {(props: FormikProps<Types.IDistributorPageProps>) => {
                  const { values, handleChange, handleBlur, handleSubmit } = props;
                  if (!(this.state.explanation && this.state.explanation.length)) this.setState({ explanation: this.props.explanation });
                  return (
                    <div className="row">
                      {T.t('gen_submit_solution_question')}
                      <div className="col-md-12 pt-3">
                        <div className="form-input form-group date-picker">
                          <input
                            id="explanation"
                            name="explanation"
                            value={this.props.explanation}
                            onChange={handleChange}
                            onBlur={(e) => this.handleChangeSubmitSolution(e, true)}
                            type="text"
                            required
                          />
                          <span className="highlight" />
                          <span className="bar" />
                          <label htmlFor="description">{'* ' + T.t('gen_description')}</label>
                        </div>
                      </div>
                    </div>
                  )
                }}
              </Formik>
            </React.Fragment>,
          name: 'submit_solution',
          icon: 'info',
          iconColor: 'green',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_cancel'),
          onConfirm: () => {
            const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
              if (status == 200) {
                this.state.statusHistoryList = [];
                window.location.reload();
              }
            };

            const model = {
              job_id: !!this.props.jobId ? this.props.jobId : this.props.distribution_in_progress_status.job_id,
              explanation: this.state.explanation,
            };

            if (this.state.explanation && this.state.explanation.length > 0) {
              this.props.dispatch(Actions.ApiRequest(Constants.disributor.DISTRIBUTOR_SUBMIT_SOLUTION, model, '', resultCallback));
            } else {
              this.props.dispatch(
                Actions.ShowModal({
                  title: T.t("gen_error"),
                  body: T.t("gen_submit_description_left_blank"),
                  name: 'submit_failed',
                  icon: 'error_outline',
                  iconColor: 'red'
                }));
            }
          }
        })
      );
    }
  };

  onTermChange = () => {
    this.props.dispatch(
      Actions.ShowModal({
        title: T.t('gen_distribution_term_change_title'),
        body: T.t('gen_distribution_term_change_body'),
        name: 'term_select',
        icon: 'info',
        iconColor: 'blue',
        confirm: T.t('gen_yes'),
        cancel: T.t('gen_cancel'),
        onConfirm: () => {
          this.props.dispatch(Actions.SetTermInfo(-1, -1));
          this.props.dispatch(
            Actions.ApiRequest(Constants.disributor.GET_DISTRIBUTION_IN_PROGRESS_STATUS, -1, 'ep-student-list')
          );
          this.props.dispatch(
            Actions.Navigation(GT.Route(Routes.EXAM_PERIODS))
          );
          this.props.dispatch(
            Actions.Navigation(
              GT.Route(
                Routes.EXAM_PERIODS
              )
            )
          );
        },
        noCloseButton: false,
        onCancel: undefined
      })
    );
  };

  render() {
    let currentTerm = this.props.term_list && this.props.term_list.length && this.props.term_id > 0 &&
      this.props.term_list.find(({ term_id }: { term_id: any }) => term_id == this.props.term_id);

    let termType = currentTerm && currentTerm.term_type > -1 ? currentTerm.term_type : -1;

    let term_info = T.t('gen_select_term');
    if (currentTerm != undefined) {
      term_info = `${currentTerm.year} ${GT.GetAcademicTermText(currentTerm.academic_term)} ${GT.GetTermTypeText(
        currentTerm.term_type
      )} / ${currentTerm.name} (ID: ${currentTerm.term_id})`;
    }

    let statusHistoryList = this.state && this.state.statusHistoryList;
    let statusHistoryMessage = this.state && this.state.statusHistory && this.state.statusHistory.message && this.state.statusHistory.message.errorMessage ?
      this.state && this.state.statusHistory && this.state.statusHistory.message && this.state.statusHistory.message.errorMessage : T.t('gen_data_preparation_pending')

    let bestSuccessRate = this.state ? this.state.best_success_rate : 0;
    let meanSuccessRate = this.state ? this.state.mean_success_rate : 0;
    let bestSuccessRateClassroom = this.state ? this.state.best_success_rate_classroom : 0;

    let isConfirmOptionalErrorsButtonVisible = this.state && this.state.status ?
      (this.state.is_error_ignorable && this.state.status == DistributorJobStatus.ImpossibilityTest_Error)
      : false;

    let isStopCalculationButtonVisible = this.state && this.state.status && this.state.solution_count != undefined ?
      (this.state.solution_count >= 0 && this.state.status == DistributorJobStatus.Calculations_Pending)
      : false;

    let isResetMaxNumberOfAttemptsButtonVisible = this.state && this.state.status && this.state.solution_count ?
      (this.state.solution_count == -2 && this.state.status == DistributorJobStatus.Calculations_Pending)
      : false;

    let isPublishStage = this.state && this.state.status ?
      (termType == 1 ? (this.state.status == DistributorJobStatus.InvigilatorAssignment_Ready) :
        (termType == 0 ? (this.state.status == DistributorJobStatus.ClassroomAssignment_Ready) : false)
      ) : false;


    let CompatibilitiesAndButtons = (
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            {bestSuccessRate > 0 ?
              <div className="col-md-2 col-sm-12 form-input form-group with-icon">
                <input
                  id="best_compatibility"
                  name="best_compatibility"
                  type="text"
                  value={bestSuccessRate}
                  disabled={true}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const newValue = e.target.value;
                    this.setState({
                      ...this.state,
                      best_compatibility: newValue
                    });
                  }}
                />
                <label htmlFor="status_log">
                  {termType == 1 ? T.t('gen_best_compatibility') :
                    (termType == 0 ? T.t('gen_course_best_compatibility') :
                      T.t('gen_error'))}
                </label>
              </div>
              : null}
            {meanSuccessRate > 0 ?
              < div className="col-md-2 col-sm-12 form-input form-group with-icon">
                <input
                  id="average_compatibility"
                  name="average_compatibility"
                  type="text"
                  value={meanSuccessRate}
                  disabled={true}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const newValue = e.target.value;
                    this.setState({
                      ...this.state,
                      average_compatibility: newValue
                    });
                  }}
                />
                <label htmlFor="status_log">{T.t('gen_average_compatibility')}</label>
              </div>
              : null}
            {termType == 0 && bestSuccessRateClassroom > 0 ?
              < div className="col-md-2 col-sm-12 form-input form-group with-icon">
                <input
                  id="best_compatibility_classroom"
                  name="best_compatibility_classroom"
                  type="text"
                  value={bestSuccessRateClassroom}
                  disabled={true}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const newValue = e.target.value;
                    this.setState({
                      ...this.state,
                      best_compatibility_classroom: newValue
                    });
                  }}
                />
                <label htmlFor="status_log">{T.t('gen_classroom_best_compatibility')}</label>
              </div>
              : null}
            <div className="col-md-12">
              {/* <button
                id='change_results'
                className="category-tag-square float-right tag-orange pr-2 pl-2 ml-3 "
                style={{ margin: "15px 0px 0px 0px" }}
                onClick={this.onSolutionResults}
              >
                <i className="material-icons mr-2">edit</i>
                <span> {T.t('gen_change_results')}</span>
              </button> */}
              {isConfirmOptionalErrorsButtonVisible ?
                <button
                  id='button_confirm_optional_errors'
                  className="category-tag-square tag-orange float-right pr-4 pl-4 mr-3"
                  onClick={this.showInfoConfirmOptionalErrors}>
                  <i className="material-icons mr-2" style={{ color: 'black' }}>done_all</i>
                  <span style={{ color: 'black' }}>{T.t('gen_validate')}</span>
                </button>
                : null}
              {isStopCalculationButtonVisible ?
                <button
                  id='button_stop_calculation'
                  className="category-tag-square tag-red float-right pr-4 pl-4 mr-3"
                  onClick={this.showInfoStopCalculation}>
                  <i className="material-icons mr-2" style={{ color: 'white' }}>stop</i>
                  <span>{T.t('gen_stop')}</span>
                </button>
                : null}
              {isResetMaxNumberOfAttemptsButtonVisible ?
                <button
                  id='button_reset_max_number_of_attempts'
                  className="category-tag-square tag-green float-right pr-4 pl-4 mr-3"
                  onClick={this.onResetMaxNumberOfAttempts}>
                  <i className="material-icons mr-2" style={{ color: 'white' }}>auto_mode</i>
                  <span>{T.t('gen_reset_max_number_of_attempts')}</span>
                </button>
                : null}
            </div>
            {
              termType == 1 && this.state.status != undefined && (isPublishStage
                || this.state.status == DistributorJobStatus.Calculations_Error
                || this.state.status == DistributorJobStatus.ClassroomAssignment_Error
                || this.state.status == DistributorJobStatus.InvigilatorAssignment_Error)
                ?
                <div className="col-md-12">
                  {this.state.status >= DistributorJobStatus.Calculations_Ready ?
                    <button
                      id='change_solution'
                      className="category-tag-square tag-orange float-left pr-2 pl-2 mr-3"
                      style={{ margin: "15px 0px 0px 0px" }}
                      onClick={this.onSolutionChange}
                    >
                      <i className="material-icons mr-2" style={{ color: 'black' }}>grading</i>
                      <span style={{ color: 'black' }}> {T.t('gen_change_solution')}</span>
                    </button>
                    : null}
                  {this.state.status >= DistributorJobStatus.InvigilatorAssignment_Ready ?
                    <button
                      id='button_reassign_invigilator_auto'
                      className="category-tag-square tag-orange float-left pr-2 pl-4 mr-3"
                      style={{ margin: "15px 0px 0px 0px" }}
                      onClick={this.showInfoReassignInvigilatorsAuto}>
                      <i className="material-icons mr-2" style={{ color: 'black' }}>people</i>
                      <span style={{ color: 'black' }}>{T.t('gen_change_invigilator') + ' (' + T.t('gen_automatic') + ')'}</span>
                    </button>
                    : null}
                  {this.state.status >= DistributorJobStatus.InvigilatorAssignment_Ready ?
                    <button
                      id='button_reassign_invigilator_manual'
                      className="category-tag-square tag-orange float-left pr-2 pl-4 mr-3"
                      style={{ margin: "15px 0px 0px 0px" }}
                      onClick={this.onReassignInvigilatorsManual}
                    >
                      <i className="material-icons mr-2" style={{ color: 'black' }}>person_search</i>
                      <span style={{ color: 'black' }}>{T.t('gen_change_invigilator') + ' (' + T.t('gen_manual') + ')'}</span>
                    </button>
                    : null}
                  {this.state.status >= DistributorJobStatus.InvigilatorAssignment_Ready && this.state.status != DistributorJobStatus.InvigilatorAssignment_Error ?
                    <button
                      id='button_change_single_exam'
                      className="category-tag-square tag-orange float-left pr-2 pl-4 mr-3"
                      style={{ margin: "15px 0px 0px 0px" }}
                      onClick={this.onSingleExamChange}
                    >
                      <i className="material-icons mr-2" style={{ color: 'black' }}>content_paste_go</i>
                      <span style={{ color: 'black' }}>{T.t('gen_change_single_exam')}</span>
                    </button>
                    : null}
                  {this.state.status >= DistributorJobStatus.InvigilatorAssignment_Ready && this.state.status != DistributorJobStatus.InvigilatorAssignment_Error ?
                    <button
                      id='submit_solution'
                      className="category-tag-square tag-green float-right pr-4 pl-4 mr-3"
                      style={{ margin: "15px 0px 0px 0px" }}
                      onClick={this.onSubmitSolution}>
                      <i className="material-icons mr-2">publish</i>
                      <span>{T.t('gen_submit_solution')}</span>
                    </button>
                    : null}
                </div> :

                termType == 0 && this.state.status != undefined && (isPublishStage
                  || this.state.status == DistributorJobStatus.Calculations_Error
                  || this.state.status == DistributorJobStatus.ClassroomAssignment_Error)
                  ?
                  <div className="col-md-12">
                    {this.state.status >= DistributorJobStatus.Calculations_Ready ?
                      <button
                        id='change_solution'
                        className="category-tag-square tag-orange float-left pr-2 pl-2 mr-3"
                        style={{ margin: "15px 0px 0px 0px" }}
                        onClick={this.onSolutionChange}
                      >
                        <i className="material-icons mr-2" style={{ color: 'black' }}>grading</i>
                        <span style={{ color: 'black' }}> {T.t('gen_change_solution')}</span>
                      </button>
                      : null}
                    {this.state.status >= DistributorJobStatus.ClassroomAssignment_Ready && this.state.status != DistributorJobStatus.ClassroomAssignment_Error ?
                      <button
                        id='button_change_calssroom'
                        className="category-tag-square tag-orange float-left pr-2 pl-4 mr-3"
                        style={{ margin: "15px 0px 0px 0px" }}
                        onClick={this.onClassroomChange}>
                        <i className="material-icons mr-2" style={{ color: 'black' }}>business</i>
                        <span style={{ color: 'black' }}>{T.t('gen_change_classroom')}</span>
                      </button>
                      : null}
                    {this.state.status >= DistributorJobStatus.ClassroomAssignment_Ready && this.state.status != DistributorJobStatus.ClassroomAssignment_Error ?
                      <button
                        id='button_change_single_course'
                        className="category-tag-square tag-orange float-left pr-2 pl-4 mr-3"
                        style={{ margin: "15px 0px 0px 0px" }}
                        onClick={this.onSingleCourseChange}
                      >
                        <i className="material-icons mr-2" style={{ color: 'black' }}>content_paste_go</i>
                        <span style={{ color: 'black' }}>{T.t('gen_change_single_course')}</span>
                      </button>
                      : null}
                    {this.state.status >= DistributorJobStatus.ClassroomAssignment_Ready && this.state.status != DistributorJobStatus.ClassroomAssignment_Error ?
                      <button
                        id='submit_solution'
                        className="category-tag-square tag-green float-right pr-4 pl-4 mr-3"
                        style={{ margin: "15px 0px 0px 0px" }}
                        onClick={this.onSubmitSolution}>
                        <i className="material-icons mr-2">publish</i>
                        <span>{T.t('gen_submit_solution')}</span>
                      </button>
                      : null}
                  </div>

                  : null}
          </div>
        </div>
      </div >
    );

    let StatusBar = (
      <div className="row">
        <div className="col-md-10 col-sm-12 mt-2">
          <span className="badge badge-secondary" style={{ fontSize: '95%', textAlign: 'center' }}>
            {statusHistoryMessage}
          </span>
        </div>
        <div className="col-md-2 col-sm-12">
          <button
            id='button_status_history'
            className="category-tag-square tag-glass float-right ml-3 mr-3 text-fit-1"
            style={{ margin: '5px' }}
            onClick={() => {
              this.state.statusTableIsOpen = !this.state.statusTableIsOpen;
              this.setState(this.state);
            }}
          >
            <i className="material-icons mr-2">filter_list</i>
            <span>{T.t('gen_status_history')}</span>
          </button>
        </div>
      </div>
    );

    let Table = (
      <div className={`row collapse ` + (this.state.statusTableIsOpen ? `show` : ``)}>
        <div className="col-12">
          <div className="quick-actions">
            <hr />
            <div className="row">
              <div className="col-9 mt-1">
                <h6>{T.t('gen_status_history')}</h6>
                <span>{T.t('gen_status_history_info')}</span>
                <hr />
              </div>
              <div className="col-3 mt-1">
                <button
                  id='button_list_sync'
                  className="category-tag-square tag-blue float-right pr-4 pl-4 mr-3"
                  style={{ margin: "15px 0px 0px 0px" }}
                  onClick={this.showInfoModalStatusHistory}>
                  <i className="material-icons mr-2" style={{ color: 'white' }}>cloud_download</i>
                  <span>{T.t('gen_download')}</span>
                </button>
              </div>
            </div>
          </div>
        </div >
        <div className="col-12">
          <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
            <thead>
              <tr>
                <th scope="col">
                  {T.t('gen_number')}
                </th>
                <SortedColumn
                  datacell="message"
                  className="d-none d-lg-table-cell d-xl-table-cell text-center"
                  title={T.t('gen_message')}
                  sortkey="message"
                  sortedcolumn={this.state.filters.order_by}
                  sort={this.sort}
                />
                <SortedColumn
                  datacell="time"
                  className="d-none d-lg-table-cell d-xl-table-cell text-center"
                  title={T.t('gen_date') + ' ' + T.t('gen_time')}
                  sortkey="time"
                  sortedcolumn={this.state.filters.order_by}
                  sort={this.sort}
                />
                <th scope="col" className="text-center">
                  {T.t('gen_actions')}
                </th>
              </tr>
            </thead>
            <tbody>
              {statusHistoryList && statusHistoryList.length ? (
                statusHistoryList.map((item: any) => {
                  let statusHistoryId = item.id ? item.id : -1;
                  let formattedErrorMessages = item.message.formattedErrorMessage ? item.message.formattedErrorMessage : []
                  return (
                    <tr key={'status-history-' + statusHistoryId} data-title={item.distribution_number}>
                      <td style={{ width: '5%', verticalAlign: 'top' }} className="text-center" data-label={T.t('gen_number')}>
                        {item.id}
                      </td>
                      <td data-label={T.t('gen_message')}>
                        {item.message.errorMessage}
                        {formattedErrorMessages && formattedErrorMessages.length ?
                          <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
                            <thead>
                              <tr>
                                <th scope="col">
                                  {T.t('gen_number')}
                                </th>
                                <th className="text-center" scope="col">
                                  {T.t('gen_details')}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {formattedErrorMessages.map((item: any, index: any) => {
                                return (
                                  <tr key={'status-history-detail-' + index}>
                                    <td style={{ width: '5%', verticalAlign: 'top' }} className="text-center" data-label={T.t('gen_number')}>
                                      {index + 1}
                                    </td>
                                    <td data-label={T.t('gen_details')}>
                                      {item}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                          : null}
                      </td>
                      <td style={{ width: '13%', verticalAlign: 'top' }} className="text-center" data-label={T.t('gen_date') + ' ' + T.t('gen_time')}>
                        {item.time}
                      </td>
                      <td style={{ width: '5%', verticalAlign: 'center', }} data-label={T.t('gen_actions')}>
                        <button
                          id='button_redirect'
                          data-toggle="tooltip"
                          title={T.t('gen_redirect')}
                          className="btn btn-outline-info btn-sm"
                          onClick={() => {
                            this.onStopDistribution(DistributorActions.Redirect, item, termType)
                          }}
                        >
                          <span className="d-block" data-target="#addNew">
                            <i className="material-icons">arrow_forward</i>
                          </span>
                        </button>

                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={24}>
                    <Announce title={T.t('gen_no_records_found')} />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div >
    );

    return (
      <Modal
        className="pt-0"
        style={{ maxWidth: '95%', padding: '0 15px' }}
        isOpen={this.props.modalIsOpen}
        toggle={this.setCloseModal}
        backdrop="static"
        shouldCloseOnOverlayClick={false}
      >
        <div className="modal-content">
          <div className="container-fluid">
            <div className="row-header align-items-center" style={{ minHeight: '70px' }}>
              <div className="col-lg-1 col-md-3">
                {appSettings.SCHEDULE
                  ? <img src={coLogo_short} alt="CO Logo" />
                  : <img src={APlanLogo} alt="APlan" style={{ width: '120px', height: '50px' }} />}
              </div>
              <div className="col-lg-11 col-md-9">
                <div className="d-md-flex justify-content-md-between align-items-md-center mobile-menu collapse">
                  <h6
                    className="neon-term-text" data-text="[Neon_Light]"
                    onClick={this.onTermChange}
                    style={{
                      cursor: 'pointer',
                      marginInlineStart: 'auto',
                      paddingRight: '16px',
                      paddingTop: '7px',
                      fontSize: '0.9rem',
                      fontStyle: 'italic'
                    }}
                  >
                    {term_info}
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-header">
            <div className="col-md-2 col-xs-12">
              <h6 className="modal-title d-inline-flex align-items-center" id="exampleModalLabel">
                {this.props.term_type === 1 ? T.t('gen_exam_distribution') : T.t('gen_activity_distribution')}
              </h6>
            </div>
            <div className="col-md-8 col-xs-12 text-center">
              <h6 className="modal-title d-inline-flex align-items-center text-center" id="distrbutionTimer">
                <Timer
                  startDate={this.state.created_date ? this.state.created_date : ''}
                  completionDate={this.state.end_date ? this.state.end_date : ''}
                />
              </h6>
            </div>
            <div className="col-md-2 col-xs-12">
              <button
                id='button_close'
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.onStopDistribution(DistributorActions.Stop, null, termType)}>
                <i className="material-icons">close</i>
                <span aria-hidden="true"></span>
              </button>
            </div>
          </div>
          <div className="modal-body">
            {CompatibilitiesAndButtons}
            <div className="white-container mt-4">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 courses">
                  {StatusBar}
                  {Table}
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          this.state.changeSolutionModalIsOpen && (
            <ChangeSolutionModal
              modalIsOpen={this.state.changeSolutionModalIsOpen}
              onClose={this.switchChangeSolutionModalStatus}
              jobId={!!this.props.jobId ? this.props.jobId : this.props.distribution_in_progress_status.job_id}
              term_id={this.props.term_id}
              term_type={termType}
            />
          )
        }
        {
          this.state.reassignInvigilatorsManualModalIsOpen && (
            <ChangeInvigilatorModal
              jobId={this.props.jobId}
              modalIsOpen={this.state.reassignInvigilatorsManualModalIsOpen}
              onClose={this.switchReassignInvigilatorsManualModalStatus}
            />
          )
        }
        {
          this.state.changeClassroomModalIsOpen && (
            <ChangeClassroomModal
              jobId={!!this.props.jobId ? this.props.jobId : this.props.distribution_in_progress_status.job_id}
              modalIsOpen={this.state.changeClassroomModalIsOpen}
              onClose={this.switchChangeClassroomModalStatus}
            />
          )
        }
        {
          this.state.changeSingleExamModalIsOpen && (
            <ChangeSingleExamModal
              jobId={!!this.props.jobId ? this.props.jobId : this.props.distribution_in_progress_status.job_id}
              modalIsOpen={this.state.changeSingleExamModalIsOpen}
              onClose={this.switchChangeSingleExamModalStatus}
            />
          )
        }
        {
          this.state.changeSingleCourseModalIsOpen && (
            <ChangeSingleCourseModal
              jobId={!!this.props.jobId ? this.props.jobId : this.props.distribution_in_progress_status.job_id}
              modalIsOpen={this.state.changeSingleCourseModalIsOpen}
              onClose={this.switchChangeSingleCourseModalStatus}
            />
          )
        }
      </Modal >
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IDistributorPageProps): Types.IDistributorPageProps => {
  if (!store) {
    return ownProps;
  }
  const newProps: Types.IDistributorPageProps = Object.assign({}, ownProps, {
    results: store.state.distributor_page && store.state.distributor_page.results,
    term_type: store.state.term_type,
    term_id: store.state.term_id,
    term_list: store.state.term_list,
    distribution_in_progress_status: store.state.distribution_in_progress_status,
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.examPeriodModal) {
    return (
      !!equal(
        prev.state.distributor_page && prev.state.distributor_page.results,
        next.state.distributor_page && next.state.distributor_page.results
      ) &&
      !!equal(
        prev.state && prev.state.term_type,
        next.state && next.state.term_type
      ) &&
      !!equal(
        prev.state && prev.state.term_id,
        next.state && next.state.term_id
      ) &&
      !!equal(
        prev.state && prev.state.distribution_in_progress_status,
        next.state && next.state.distribution_in_progress_status
      )
    );
  } else {
    return true;
  }
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(DistributorModal);

export default container;
